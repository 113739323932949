import { Client } from '@/utilities/api/Client';
import { AccountGroupCustomerEndpoint } from '@api/endpoints';

export class AccountGroupCustomerApi {
  static update (
    id: string,
    data: AccountGroupCustomerEndpoint.Update.Request,
  ): Promise<AccountGroupCustomerEndpoint.Update.Response> {
    return Client(`account-group-customers/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }
}
