import React, { useState } from "react";
import { PromotionInputs } from "@/pages/Admin/Promotions/PromotionModal/PromotionModal";
import { ApiRetailer } from "@api/interfaces";
import { ProductList } from "@/components/shared/ListSelector/ProductList";
import { Button, HSpacer, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import { Avatar, CircularProgress, Container, Stack } from "@mui/material";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { AppConfig } from "@/constants/AppConfig";
import Store from "@mui/icons-material/Store";
import { ManufacturerApi } from "@/utilities/api/ManufacturerApi";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddedProductsSideSheet,
} from "@/pages/Admin/Promotions/PromotionModal/AddedProductsSideSheet";

interface WorkflowStep3Props {
  onPromotionChanged: (promotion: PromotionInputs) => void,
  productType: 'earn' | 'redeem',
  promotion: PromotionInputs,
  selectedRetailer?: ApiRetailer,
}

export const WorkflowStep3 = ({
  onPromotionChanged,
  productType,
  promotion,
  selectedRetailer,
}: WorkflowStep3Props) => {
  const [showAddedProductsSideSheet, setShowAddedProductsSideSheet] = useState(false);
  const [showHelpOption, setShowHelpOption] = useState(true);

  const { data: selectedManufacturer, isFetching: isFetchingManufacturer } = useQuery(
    [QueryKeys.GET_MANUFACTURER_BY_ID, promotion.selectedManufacturerId],
    () => promotion.selectedManufacturerId ?
      ManufacturerApi.get(promotion.selectedManufacturerId) : null,
    { enabled: !!promotion.selectedManufacturerId },
  );

  if (!selectedRetailer) {
    return null;
  }

  const imageUrl = selectedRetailer.image &&
    `${AppConfig.staticImageHost}/${selectedRetailer.image}`;

  const productIds = productType === 'earn'
    ? promotion.productIdsForEarningPoints ?? []
    : promotion.productIdsForRedeemingPoints ?? [];

  const updateProducts = (productIds: string[]) => {
    if (productType === 'earn') {
      onPromotionChanged({
        ...promotion,
        productIdsForEarningPoints: productIds.length === 0 ? undefined : productIds,
      });
    } else {
      onPromotionChanged({
        ...promotion,
        productIdsForRedeemingPoints: productIds.length === 0 ? undefined : productIds,
      });
    }
  };

  const totalEarningProducts = promotion.productIdsForEarningPoints?.length ?? 0;

  if (isFetchingManufacturer) {
    return <Container>
      <Stack alignItems='center'>
        <VSpacer size='14' />
        <CircularProgress />
      </Stack>
    </Container>;
  }

  return <>
    <Stack direction="row" justifyContent="center">
      <Avatar
        alt={`${selectedRetailer.name} logo`}
        src={imageUrl ?? undefined}
        sx={{
          bgcolor: '#EBEBEB',
          height: 18,
          width: 18,
        }}
      >
        {imageUrl ? null : (
          <Store sx={{ height: 18, width: 18 }} />
        )}
      </Avatar>
      <HSpacer size="4" />
      <Text category="title-small">{selectedRetailer.name}:</Text>
      <HSpacer size="2" />
      <Text category="body-medium">{selectedManufacturer
        ? `All ${selectedManufacturer.name} products`
        : "All products"
      }</Text>
    </Stack>
    {showHelpOption && productType === 'redeem' && (
      <Stack sx={{ "margin": "0px 24px" }}>
        <VSpacer size="7" />
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{
          backgroundColor: "white",
          padding: "8px 16px",
        }}>
          <Text category="title-small" sx={{ "color": "#141414" }}>
            {totalEarningProducts > 1
              ? `All ${totalEarningProducts}`
              : "All"
            } products that EARN points also REDEEM points?
          </Text>
          <Stack direction="row">
            <Button
              onClick={() => setShowHelpOption(false)}
              square
              sx={{
                color: "white",
                backgroundColor: "#818B15",
                borderRadius: 1,
                flex: 1,
                width: 56,
              }}
              testID="show-help-option-no"
              variant="contained"
            >
              No
            </Button>
            <HSpacer size="5" />
            <Button
              onClick={() => {
                onPromotionChanged({
                  ...promotion,
                  productIdsForRedeemingPoints: Array.from(new Set([
                    ...promotion.productIdsForEarningPoints ?? [],
                    ...promotion.productIdsForRedeemingPoints ?? [],
                  ])),
                });
                setShowHelpOption(false);
              }}
              square
              sx={{
                color: "white",
                backgroundColor: "#818B15",
                borderRadius: 1,
                flex: 1,
                width: 56,
              }}
              testID="show-help-option-no"
              variant="contained"
            >
              Yes
            </Button>
            <HSpacer size="5" />
            <IconButton
              onClick={() => setShowHelpOption(false)}
              size="small"
              sx={{ color: "black" }}
              testID="show-help-option-close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    )}
    <ProductList
      onAddedProductsButtonClicked={() => setShowAddedProductsSideSheet(true)}
      onChange={updateProducts}
      requiredManufacturerID={promotion.selectedManufacturerId}
      requiredUOM={productType === 'earn' ? promotion.uom : undefined}
      selectedIds={productIds}
      selectedRetailer={selectedRetailer}
    />
    {showAddedProductsSideSheet && (
      <AddedProductsSideSheet
        onChange={updateProducts}
        onClose={() => setShowAddedProductsSideSheet(false)}
        productIds={productIds}
        productType={productType}
      />
    )}
  </>;
};