import { ProductBundleCard } from '@/components/shared/ProductBundle/ProductBundleCard';
import { QueryKeys } from '@/constants/QueryKeys';
import { ProductCard } from '@/pages/Admin/PricingRequestList/ProductCard';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { ApiPricingRequestProduct, RecommendationProduct } from '@api/interfaces';
import { ApiBundle } from '@api/interfaces/ApiBundle';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { formatDateOnly } from '@shared/utilities';
import React from 'react';
import { useQuery } from 'react-query';

const ProductListTab = ({
  bundles,
  products,
  promotionSnapshotDate,
  showPrices = false,
}: {
  bundles: ApiBundle[],
  products: ApiPricingRequestProduct[] | RecommendationProduct[],
  promotionSnapshotDate?: Date,
  showPrices?: boolean,
}) => {
  const productIds = products.map(
    (product) => product.productId,
  ).filter(Boolean) as string[] ?? [];

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds, promotionSnapshotDate],
    async () => {
      const promotions = await PromotionApi.list({
        isActive: true,
        productIds: productIds,
        snapshotDate: promotionSnapshotDate,
      });
      const currentDate = formatDateOnly(promotionSnapshotDate
        ? new Date(promotionSnapshotDate)
        : new Date(),
      );
      return promotions.data.filter((promotion) => (
        promotion.startDate <= currentDate
      ));
    },
    {
      enabled: !!productIds.length,
    },
  );

  const getFarmerPromotions = (productId?: string | null) => {
    if (!productId) {
      return [];
    }
    return promotions?.filter(
      (promotion) => promotion.targetUserType === PromotionTargetUserType.Farmer
        && promotion.productIdsForEarningPoints.includes(productId),
    ) ?? [];
  };

  const getRetailerPromotions = (productId?: string | null) => {
    if (!productId) {
      return [];
    }
    return promotions?.filter(
      (promotion) => promotion.targetUserType === PromotionTargetUserType.Retailer
        && promotion.productIdsForEarningPoints.includes(productId),
    ) ?? [];
  };
  return (
    <>
      {bundles.map((bundle) => (
        <ProductBundleCard bundle={bundle} key={bundle.id} testID={`${bundle.id}-bundle-card`} />
      ))}
      {products.map((product) => (
        <ProductCard
          farmerPromotions={getFarmerPromotions(product.productId)}
          key={product.id}
          product={product}
          retailerPromotions={getRetailerPromotions(product.productId)}
          showPrices={showPrices}
        />
      ))}
    </>
  );
};

export default ProductListTab;
