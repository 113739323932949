import {
  Button,
  Card,
  Chip,
  DataPoint,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import {
  CreateNotificationInputs,
  CreateNotificationModal,
} from '@/pages/Admin/ManageNotifications/CreateNotificationModal';
import {
  ViewAddedRecipientsModal,
} from '@/pages/Admin/ManageNotifications/ViewAddedRecipientsModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ScheduledNotificationApi } from '@/utilities/api/ScheduledNotificationApi';
import { ScheduledNotificationEndpoint } from '@api/endpoints';
import { ApiScheduledNotification } from '@api/interfaces';
import { Warning } from '@mui/icons-material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Stack } from '@mui/material';
import { omit } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ScheduledNotificationType } from '@shared/enums/ScheduledNotificationType';

interface ScheduledNotificationCardProps {
  notification: ApiScheduledNotification,
}

export const ScheduledNotificationCard = ({
  notification,
}: ScheduledNotificationCardProps) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();

  const [showViewRecipientsModal, setShowViewRecipientsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const createdDate = DateTime.fromJSDate(notification.createdAt).toFormat('MMM dd');

  const isCustom = notification.type === ScheduledNotificationType.Custom;
  const luxonSendDate = DateTime.fromJSDate(notification.sendDate);
  const sendDateDate = luxonSendDate.toFormat('MMM dd, y');
  const sendDateTime = luxonSendDate.toFormat('h:mma')
    .replace('AM', 'am')
    .replace('PM', 'pm');
  const sendDate = `${sendDateDate} at ${sendDateTime}`;
  const title = notification.title ?? `${notification.type} notification`;

  const { mutateAsync: deleteNotification } = useMutation(
    () => {
      return ScheduledNotificationApi.delete(notification.id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification deleted successfully');
      },
      onError: () => {
        openSnackbar('Failed to delete custom notification');
      },
    },
  );

  const { mutateAsync: updateNotification } = useMutation(
    (args: ScheduledNotificationEndpoint.Update.Request) => {
      return ScheduledNotificationApi.update(notification.id, args);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification edited successfully');
      },
      onError: () => {
        openSnackbar('Failed to edit custom notification');
      },
    },
  );

  const handleDeleteNotification = async () => {
    setShowDeleteModal(false);
    await deleteNotification();
  };

  const handleEditNotification = async (inputs: CreateNotificationInputs) => {
    await updateNotification({
      ...omit(inputs, ['farmerIds', 'salespersonIds']),
      recipientIds: inputs.farmerIds.concat(inputs.salespersonIds),
    });
  };

  return (
    <Card testID={notification.id}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <Text category="title-medium">{title}</Text>
          <VSpacer size="2" />
          <Text category="body-small">Created on {createdDate}</Text>
        </Stack>
        {!notification.isSent && !notification.deletedAt && isCustom && (
          <Stack direction="row">
            <IconButton
              color="inherit"
              onClick={() => setShowEditModal(true)}
              testID={`${notification.id}-edit-button`}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => setShowDeleteModal(true)}
              testID={`${notification.id}-delete-button`}
            >
              <DeleteOutline />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <VSpacer size="3" />
      <Chip
        color={notification.isSent ? 'info' : 'warning'}
        label={notification.isSent ? 'Sent' : 'Pending'}
        testID={`${notification.id}-status-chip`}
      />
      <VSpacer size="5" />
      <Text category="body-medium">{notification.message}</Text>
      <VSpacer size="4" />
      <Stack alignItems="center" direction="row">
        <DataPoint label="Send date:" testID={`${notification.id}-send-date-data-point`}>
          {sendDate}
        </DataPoint>
        <HSpacer size="5" />
        {isCustom ? (
          <Button
            onClick={() => setShowViewRecipientsModal(true)}
            testID={`${notification.id}-recipients-button`}
            variant="text"
          >
            {notification.recipientIds?.length ?? 0} recipients
          </Button>
        ) : (
          <Text category="title-small" color="primary">
            {notification.retailerIds?.length ?? 0}
            {' '}
            retailer{notification.retailerIds?.length !== 1 ? 's' : ''}
          </Text>
        )}
      </Stack>
      {showViewRecipientsModal && (
        <ViewAddedRecipientsModal
          onClose={() => setShowViewRecipientsModal(false)}
          recipientIds={notification.recipientIds ?? []}
          testID={`${notification.id}-view-recipients-modal`}
        />
      )}
      {showEditModal && (
        <CreateNotificationModal
          notification={notification}
          onClose={() => setShowEditModal(false)}
          onSubmit={handleEditNotification}
          testID={`${notification.id}-edit-notification-modal`}
          type={ScheduledNotificationType.Custom}
        />
      )}
      {showDeleteModal && (
        <Dialog
          acceptButton={() => (
            <Button
              onClick={handleDeleteNotification}
              testID={`${notification.id}-delete-confirm-button`}
              variant="text"
            >
              Yes, delete
            </Button>
          )}
          cancelButton={() => (
            <Button
              color="inherit"
              onClick={() => setShowDeleteModal(false)}
              testID={`${notification.id}-delete-cancel-button`}
              variant="text"
            >
              Go back
            </Button>
          )}
          heroIcon={<Warning color="warning" />}
          onClose={() => setShowDeleteModal(false)}
          open
          showCloseButton={false}
          testID={`${setShowDeleteModal}-delete-confirmation-dialog`}
          title="Delete Custom Notification?"
        />
      )}
    </Card>
  );
};
