import { Button, ProgressLoader, Text, Toolbar, VSpacer } from "@/components/DesignSystem";
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { QueryKeys } from '@/constants/QueryKeys';
import { useRetailerLocationFilter } from "@/hooks/useRetailerLocationFilter";
import { useSearch } from '@/hooks/useSearch';
import { PromotionCard } from '@/pages/Admin/Promotions/PromotionCard';
import {
  PromotionInputs,
  PromotionModal,
} from '@/pages/Admin/Promotions/PromotionModal/PromotionModal';
import { ManufacturerApi } from "@/utilities/api/ManufacturerApi";
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { ApiProduct } from '@api/interfaces';
import Add from '@mui/icons-material/Add';
import { Container, Pagination, Stack } from "@mui/material";
import { Modify } from '@shared/utilities/UtilityTypes';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { formatDateOnly } from "@shared/utilities";
import { PromotionEndpoint } from "@api/endpoints";
import { ToastMessages } from "@/constants/constant";
import { useSnackbar } from "@/providers/GlobalSnackbarProvider";
import { DetailedApiError } from "@/utilities/api/DetailedApiError";
import { RewardsType } from "@shared/enums/RewardsType";
import { round } from "lodash";

export const Promotions = () => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { setSearch, debouncedSearch } = useSearch(1);

  const [page, setPage] = useState(0);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [selectionsString, debouncedSearch]);

  async function handleDelete (id: string) {
    await PromotionApi.delete(id);
    await queryClient.invalidateQueries(QueryKeys.GET_PROMOTIONS);
  }

  async function onSave (promotion: PromotionInputs) {
    try {
      await createPromotion(promotion as Omit<Required<PromotionInputs>, 'id'>);
      await queryClient.invalidateQueries([QueryKeys.GET_PROMOTIONS]);
      openSnackbar(ToastMessages.promotionCreated);
      setShowPromotionModal(false);
    } catch (err) {
      if (err instanceof DetailedApiError) {
        openSnackbar(err.message);
      }
    }
  }

  const { mutateAsync: createPromotion } = useMutation(
    async (promotion: Omit<Required<PromotionInputs>, 'id'>) => {
      const requestBody = {
        ...promotion,
        startDate: formatDateOnly(promotion.startDate),
        endDate: formatDateOnly(promotion.endDate),
        value: promotion.rewardType === RewardsType.Dollars
          ? round(promotion.value, 4)
          : round(promotion.value, 5),
      };
      return PromotionApi.create(requestBody as PromotionEndpoint.Create.Request);
    },
  );

  const { data: products } = useQuery(
    [QueryKeys.GET_PRODUCTS],
    async () => ProductApi.getProductList({ limit: 1000 }),
    { staleTime: 5 * 60 * 1000 },
  );
  const productsWithNames = useMemo(() => (
    products?.data.filter(({ name }) => name) ?? []
  ) as Modify<ApiProduct, { name: string }>[], [products]);

  const { data: manufacturers } = useQuery(
    [QueryKeys.GET_MANUFACTURERS],
    async () => ManufacturerApi.list(),
    { staleTime: 5 * 60 * 1000 },
  );

  const retailerLocationFilter = useRetailerLocationFilter();
  const filters: Filter[] = [
    {
      id: 'show-expired',
      label: 'Show Expired',
      options: [
        { id: 'show-expired', label: 'Show Expired' },
      ],
      selectionMethod: 'boolean',
    },
    retailerLocationFilter,
    {
      id: 'products',
      label: 'Products',
      options: productsWithNames.map(({ id, name }) => ({
        id,
        label: name,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
    {
      id: 'manufacturers',
      label: 'Manufacturers',
      options: manufacturers?.map(({ id, name }) => ({
        id,
        label: name ?? id,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
  ];

  const isActive = !filterSelections?.get('show-expired')?.has('show-expired') || undefined;
  const manufacturerIds = Array.from(filterSelections?.get('manufacturers') ?? []);
  const productIds = Array.from(filterSelections?.get('products') ?? []);
  const locationIds = Array.from(filterSelections?.get(retailerLocationFilter.id) ?? []);
  const { data: promotions, isFetching: arePromotionsFetching } = useQuery(
    [QueryKeys.GET_PROMOTIONS, debouncedSearch, selectionsString, page],
    async () => PromotionApi.list({
      isActive,
      productIds,
      locationIds,
      manufacturerIds,
      page,
      limit: 20,
      search: debouncedSearch,
      sort: 'endDate',
    }),
    { staleTime: 5 * 60 * 1000 },
  );

  const Header = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Text category="title-large">
          Promotions
        </Text>
        <Button
          onClick={() => {
            setShowPromotionModal(true);
          }}
          startIcon={<Add />}
          testID="create-promotion-button"
        >
          Create Promotion
        </Button>
      </Stack>
      <VSpacer size="5" />
      <Toolbar
        filters={filters}
        onChange={({ search, selections }) => {
          setSearch(search ?? '');
          setFilterSelections(selections);
        }}
        showClearAllButton
        testID="promotions-toolbar"
        totalItems={promotions?.total}
        totalUnit="promotion"
      />
    </>
  );

  const PromotionCards = !!promotions?.data.length && (
    <Stack gap="16px">
      {promotions.data.map((promotion) => (
        <PromotionCard
          key={promotion.id}
          onDelete={() => {
            setSelectedId(promotion.id);
            setShowDeleteDialog(true);
          }}
          onEdit={() => {
            setSelectedId(promotion.id);
            setShowPromotionModal(true);
          }}
          onView={() => {
            setSelectedId(promotion.id);
            setShowPromotionModal(true);
          }}
          promotion={promotion}
          testID={`promotion-${promotion.name}`}
        />
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {arePromotionsFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : PromotionCards}
        {!!promotions?.total && promotions?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={promotions?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={promotions?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {showPromotionModal && (
        <PromotionModal
          onClose={() => {
            setShowPromotionModal(false);
            setSelectedId(undefined);
          }}
          onSave={onSave}
          show
        />
      )}
      {showDeleteDialog && !!selectedId && (
        <ConfirmDialog
          cancelText="Go back"
          confirmText="Yes, delete"
          onCancel={() => {
            setShowDeleteDialog(false);
            setSelectedId(undefined);
          }}
          onConfirm={async () => {
            setShowDeleteDialog(false);
            await handleDelete(selectedId);
            setSelectedId(undefined);
          }}
          open
          testID={'delete-promotion-dialog'}
          title="Delete Promotion"
        >
          Are you sure you want to delete this promotion?
        </ConfirmDialog>
      )}
    </>
  );
};
