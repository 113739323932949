import { Card, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { TestUserBadge } from '@/components/shared/TestUserBadge';
import { Routes } from '@/constants/Routes';
import { ApiUser } from '@api/interfaces';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Stack } from '@mui/material';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

type CustomerCardProps = {
  customer: ApiUser;
};

const CustomerCard = ({ customer }: CustomerCardProps) => {
  const navigate = useNavigate();
  const accountName = getAccountName(customer);
  return (
    <Card
      onClick={() => navigate(
        Routes.CUSTOMER_DETAILS.replace(':id', customer.id),
      )}
      sx={{ cursor: 'pointer', padding: "16px" }}
      testID={`${customer.id}-list-item`}
    >
      <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
        <Stack>
          {accountName !== customer.businessName && (
            <>
              <Text category="overline">{customer.businessName}</Text>
              <VSpacer size="4" />
            </>
          )}
          <Text category="body-xlarge">{accountName}</Text>
          <VSpacer size="4" />
          <Text category="body-small">
            {customer.externalId ? `ERP ID: ${customer.externalId}` : `Growers ID: ${customer.id}`}
          </Text>
        </Stack>
        <Stack flexDirection="row">
          {customer.isTest && (
            <TestUserBadge />
          )}
          <IconButton
            testID={`${customer.id}-navigate-button`}
            variant="outlined"
          >
            <KeyboardArrowRight />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CustomerCard;
