import { Button, Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { Action } from '@/components/shared/ListSelector/helper';
import { ProductCard } from '@/components/shared/ListSelector/ProductCard';
import { QueryKeys } from '@/constants/QueryKeys';
import { useCategoryList, useManufacturerList } from '@/hooks/useProductQuery';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ProductApi } from '@/utilities/api/ProductApi';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { ProductEndpoint } from '@api/endpoints';
import { ApiProduct, ApiPromotion, ApiRetailer } from '@api/interfaces';
import { CircularProgress, Container, Pagination, Stack } from '@mui/material';
import { PromotionTargetUserType } from '@shared/enums/PromotionTargetUserType';
import { SharedConfig } from '@shared/SharedConfig';
import { ConversionUtility, formatDateOnly } from '@shared/utilities';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from '@/assets/icons/group.svg';
import { RewardsUom } from "@shared/enums/RewardsUom";

interface ProductListProps {
  onChange: (updatedIds: string[]) => void,
  requiredManufacturerID?: string,
  requiredUOM?: RewardsUom,
  selectedIds: string[],
  selectedRetailer: ApiRetailer,
  onAddedProductsButtonClicked?: () => void,
}

export const ProductList = ({
  onChange,
  requiredUOM,
  requiredManufacturerID,
  selectedIds,
  selectedRetailer,
  onAddedProductsButtonClicked,
}: ProductListProps) => {
  const [page, setPage] = useState(0);
  const { setSearch, debouncedSearch } = useSearch(3);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const { categoryList } = useCategoryList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
  });

  const { manufacturerList } = useManufacturerList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
    hasErpProducts: !!selectedRetailer,
  }, {
    enabled: !requiredManufacturerID,
  });

  const { openSnackbar } = useSnackbar();
  const [isAddingAll, setIsAddingAll] = useState(false);

  const selectedCategoryId = Array.from(filterSelections?.get('category-id') ?? []);
  const selectedManufacturerId = requiredManufacturerID
    ? [requiredManufacturerID]
    : Array.from(filterSelections?.get('manufacturer-id') ?? []);
  const selectedRetailerId = Array.from(filterSelections?.get('retailer-id') ?? []);

  const query: ProductEndpoint.ProductList.Query = {
    categoryId: selectedCategoryId,
    hasExternalId: !!selectedRetailer,
    manufacturerId: selectedManufacturerId,
    page,
    retailerId: selectedRetailer ? [selectedRetailer.id] : selectedRetailerId,
    search: debouncedSearch,
  };

  const { data: products, isLoading } = useQuery(
    [QueryKeys.GET_PRODUCT, query],
    () => ProductApi.productListData(query),
  );

  const productIds = products?.data.map((product) => product.id);
  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS, productIds],
    async () => PromotionApi.list({
      isActive: true,
      productIds: productIds,
    }),
    {
      enabled: !!productIds?.length,
    },
  );

  const currentDate = formatDateOnly(new Date());
  const getFarmerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.targetUserType === PromotionTargetUserType.Farmer
        && promotion.productIdsForEarningPoints.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const getRetailerPromotions = (product: ApiProduct): ApiPromotion[] => {
    return promotions?.data.filter(
      (promotion) => (
        promotion.targetUserType === PromotionTargetUserType.Retailer
        && promotion.productIdsForEarningPoints.includes(product.id)
        && promotion.startDate <= currentDate && promotion.endDate >= currentDate
      ),
    ) ?? [];
  };

  const categoryOptions = categoryList?.map((category) => ({
    id: category.id,
    label: category.name ?? '',
  })).sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const addAll = async () => {
    if ((products?.total ?? 0) > SharedConfig.maxPageLimit) {
      openSnackbar('Too many products. Please filter down the list.');
      return;
    }
    setIsAddingAll(true);
    const { data: fullListOfProducts } = await ProductApi.productListData(
      { ...query, limit: SharedConfig.maxPageLimit },
    );
    const productIdsToAdd = fullListOfProducts
      .filter((p) => !isUnavailable(p))
      .map((p) => p.id);
    
    const updatedIdList = new Set([...selectedIds, ...productIdsToAdd]);
    onChange(Array.from(updatedIdList));
    setIsAddingAll(false);
  };

  const isUnavailable = (product: ApiProduct) => {
    if (!requiredUOM || requiredUOM === RewardsUom.Dollars) {
      return false;
    }
    for (const uom of product.purchaseUom) {
      if (uom !== requiredUOM && !ConversionUtility.isConvertable(uom, requiredUOM)) {
        return true;
      }
    }
    return false;
  };

  const getFilters = (): Filter[] => {
    const filters: Filter[] = [
      {
        id: 'category-id',
        label: 'Category',
        options: categoryOptions,
        selectionMethod: 'single-select',
      },
    ];
    if (!requiredManufacturerID) {
      filters.push({
        id: 'manufacturer-id',
        label: 'Manufacturer',
        options: manufacturerList?.map((manufacturer) => ({
          id: manufacturer.id,
          label: manufacturer.name ?? manufacturer.id,
        })).sort((a, b) => a.label.localeCompare(b.label)) ?? [],
        selectionMethod: 'single-select',
      });
    }
    return filters;
  };

  const hasUnavailableProducts = !!products?.data.some(isUnavailable);

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, filterSelections]);

  return (
    <Container maxWidth="lg">
      <VSpacer size="6" />
      <Stack direction="row" justifyContent="space-between">
        <Toolbar
          filterSelections={filterSelections}
          filters={getFilters()}
          infoTooltip={{
            labelText: "Product unavailable",
            tooltipText: `Products that are listed as unavailable are not eligible to be added to 
              this promotion. This is due to the product not being sold by the same UOM that is
              selected within the promotion loyalty value. For these products, we are
              unable to calculate a loyalty value conversion.`,
            visible: hasUnavailableProducts,
          }}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          retainSelectionsOnFilterChange
          testID="product-list-toolbar"
          totalItems={products?.total ?? 0}
          totalUnit="product"
        />
        <Stack alignItems="end" direction="column" justifyContent="space-between">
          {!!onAddedProductsButtonClicked && (
            <Stack>
              <VSpacer size="3"/>
              <Button
                disabled={!selectedIds.length}
                loading={isAddingAll}
                onClick={onAddedProductsButtonClicked}
                startIcon={<img src={GroupIcon} style={{ height: '18px', width: '18px' }}/>}
                testID="add-all-products-button"
                variant="contained"
              >
                Added products ({selectedIds.length})
              </Button>
            </Stack>
          )}
          <Button
            disabled={!products?.total}
            loading={isAddingAll}
            onClick={async () => {
              await addAll();
            }}
            startIcon={<AddIcon />}
            testID="add-all-button"
            variant="text"
          >
            Add all
          </Button>
        </Stack>
      </Stack>
      <VSpacer size="5" />
      {products?.data.map((product) => (
        <React.Fragment key={product.id}>
          <ProductCard
            farmerPromotions={getFarmerPromotions(product)}
            onAction={(action: Action) => {
              const updatedSelectedIds = new Set(selectedIds);
              if (action === Action.AddAll) {
                updatedSelectedIds.add(product.id);
              } else {
                updatedSelectedIds.delete(product.id);
              }
              onChange(Array.from(updatedSelectedIds));
            }}
            product={product}
            retailerPromotions={getRetailerPromotions(product)}
            selectedIds={selectedIds}
            unavailable={isUnavailable(product)}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
      <VSpacer size='8' />
      {isLoading ? (
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='center'
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {products && !!products.lastPage && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
            >
              <Pagination
                count={products.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={products.page + 1}
              />
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};