import IconDot from '@/assets/icons/icon-dot.svg';
import {
  Button,
  Card,
  Chip,
  DataPoint,
  HSpacer,
  IconButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { FriendlyTargetUserType } from '@/constants/FriendlyTargetUserType';
import { Color } from '@/themes/MUITheme/palette';
import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Stack } from '@mui/material';
import { formatCurrency, localizeNumber } from '@shared/utilities';
import { DateTime } from 'luxon';
import React from 'react';
import { RewardsType } from "@shared/enums/RewardsType";

interface PromotionCardProps {
  promotion: ApiPromotion,
  onDelete: () => void,
  onEdit: () => void,
  onView: () => void,
  testID: string,
}

const statusColors: { [key: string]: Color | undefined } = {
  [PromotionStatus.FUTURE]: 'info',
  [PromotionStatus.RUNNING]: 'success',
  [PromotionStatus.EXPIRED]: undefined,
};

function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
}

export const PromotionCard = ({
  onDelete,
  onView,
  promotion,
  testID,
}: PromotionCardProps) => {
  const statuses = getStatuses(promotion);
  const canBeEdited = statuses.includes(PromotionStatus.FUTURE);

  const Header = (
    <Stack
      alignItems='top'
      direction='row'
      justifyContent={'space-between'}
    >
      <Stack>
        <Text category="overline" sx={{ color: '#D8D8D8' }}>
          {FriendlyTargetUserType[promotion.targetUserType]}
        </Text>
        <VSpacer size="1" />
        <Text category="title-medium">
          {promotion.name}
        </Text>
      </Stack>
      <Stack alignItems='top' direction='row' spacing={2}>
        <Button
          onClick={onView}
          size="medium"
          sx={{ padding: '10px 24px 10px 24px' }}
          testID={`${testID}-view-button`}
          variant="outlined"
        >
          View
        </Button>
        <IconButton
          color="primary"
          disabled={!canBeEdited}
          onClick={onDelete}
          testID={`${testID}-delete-button`}
        >
          <DeleteOutline />
        </IconButton>
      </Stack>
    </Stack>
  );

  const StatusChips = (
    <Stack direction="row" gap="8px">
      {statuses.map((status) => (
        <Chip
          color={statusColors[status]}
          key={status}
          label={status.toUpperCase()}
          testID={`${testID}-status-${status}`}
        />
      ))}
    </Stack>
  );

  const Details = (
    <Stack>
      <Stack direction="row">
        <DataPoint label="Loyalty:" py={0} testID="promotion-value-datapoint">
          {promotion.rewardType === RewardsType.Percent
            ? `${localizeNumber(promotion.value * 100, 5)}%`
            : formatCurrency(promotion.value)
          }
        </DataPoint>
        <HSpacer size="3" />
        <img src={IconDot} />
        <HSpacer size="3" />
        <DataPoint label="Products:" py={0} testID="promotion-products-datapoint">
          {promotion.productIdsForEarningPoints.length}
        </DataPoint>
        <HSpacer size="3" />
        <img src={IconDot} />
        <HSpacer size="3" />
        <DataPoint label="Retailer locations:" py={0} testID="promotion-locations-datapoint">
          {promotion.locationIds.length}
        </DataPoint>
      </Stack>
      <VSpacer size="4" />
      <Stack direction="row">
        <DataPoint label="Start date:" py={0} testID="promotion-start-date-datapoint">
          {formatDate(promotion.startDate)}
        </DataPoint>
        <HSpacer size="3" />
        <img src={IconDot} />
        <HSpacer size="3" />
        <DataPoint label="End date:" py={0} testID="promotion-end-date-datapoint">
          {formatDate(promotion.endDate)}
        </DataPoint>
      </Stack>
    </Stack>
  );

  return (
    <Card
      cardContentStyle={{ padding: '16px' }}
      testID={testID}
    >
      {Header}
      <VSpacer size="3" />
      {StatusChips}
      <VSpacer size="3" />
      {Details}
    </Card>
  );
};
