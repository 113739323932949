import { Button, Card, HSpacer, IconButton, Text, VSpacer } from "@/components/DesignSystem";
import React, { useState } from "react";
import { PromotionInputs } from "@/pages/Admin/Promotions/PromotionModal/PromotionModal";
import { Divider, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { ApiRetailer, ApiRetailerLocation } from "@api/interfaces";
import { EditLocationsModal } from "@/pages/Admin/Promotions/PromotionModal/EditLocationsModal";
import { getSelectedLocationsText } from "@/pages/Admin/Promotions/PromotionModal/Helpers";
import { RewardsUom } from "@shared/enums/RewardsUom";
import { RewardsType } from "@shared/enums/RewardsType";
import { SponsorType } from "@shared/enums";
import {
  AddedProductsSideSheet,
} from "@/pages/Admin/Promotions/PromotionModal/AddedProductsSideSheet";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const SectionDivider = (
  <>
    <VSpacer size="3" />
    <Divider />
    <VSpacer size="3" />
  </>
);

const EditSectionSubLine = ({ label, value } : {
  label: string;
  value: boolean;
}) => {
  return <Stack direction="row">
    {value
      ? <Check color="success" sx={{ width: "20px", height: "20px" }} />
      : <CloseIcon color="error" sx={{ width: "20px", height: "20px" }} />
    }
    <HSpacer size="3" />
    <Text category="body-medium">{label}</Text>
  </Stack>;
};

interface EditSectionProps {
  children?: React.ReactNode,
  label: string,
  onEditButtonClicked: () => void,
  onValueClicked?: () => void,
  value?: string,
}

const EditSection = ({
  children,
  label,
  onEditButtonClicked,
  onValueClicked,
  value,
} : EditSectionProps) => <>
  <Stack alignItems="center" direction="row" justifyContent="space-between">
    <Text category="body-large">{label}</Text>
    <Stack alignItems="center" direction="row">
      {onValueClicked
        ? <Button
          color="primary"
          onClick={onValueClicked}
          testID='locations-edit-button'
          variant='text'
        >
          <Text category="body-large">{value}</Text>
        </Button>
        : <Text category="body-large">{value}</Text>
      }
      <HSpacer size="5" />
      <IconButton
        onClick={onEditButtonClicked}
        testID='promotion-edit-icon'
      >
        <EditIcon color="primary" />
      </IconButton>
      <HSpacer size="2" />
    </Stack>
  </Stack>
  <VSpacer size="2" />
  {children}
</>;

const AdditionalInfoSection = ({
  onChange,
  promotion,
} : {
  onChange: (description: string) => void;
  promotion: PromotionInputs;
}) => {
  const [editAdditionalInfo, setEditAdditionalInfo] = useState(false);
  const [description, setDescription] = useState('');

  if (editAdditionalInfo) {
    return <Stack>
      {SectionDivider}
      <VSpacer size="3" />
      <Text category="body-large">
        Additional information (optional)
      </Text>
      <VSpacer size="3" />
      <Text category="body-small" sx={{ color: "#D8D8D8" }}>
        Provide critical promotion details such as terms & conditions, disclaimer
        text or other rules.
      </Text>
      <VSpacer size="5" />
      <TextField
        defaultValue={promotion.description}
        multiline
        onChange={(e) => setDescription(e.target.value)}
        rows={12}
        variant="filled"
      />
      <VSpacer size="5" />
      <Stack direction="row" justifyContent="end">
        <Button
          onClick={() => setEditAdditionalInfo(false)}
          square
          testID="additional-info-cancel-button"
          variant="outlined"
        >
          <Text category="label-medium">
            Cancel
          </Text>
        </Button>
        <HSpacer size="5" />
        <Button
          onClick={() => {
            onChange(description);
            setEditAdditionalInfo(false);
          }}
          square
          testID="additional-info-save-button"
        >
          <Text category="label-medium">
            Save
          </Text>
        </Button>
        <HSpacer size="3" />
      </Stack>
    </Stack>;
  }

  if (promotion.description) {
    return <>
      {SectionDivider}
      <EditSection
        label="Additional information (optional)"
        onEditButtonClicked={() => setEditAdditionalInfo(true)}
      >
        <Text category="body-small" sx={{ color: "#D8D8D8" }}>
          Provide critical promotion details such as terms & conditions, disclaimer text
          or other rules.
        </Text>
        <VSpacer size="5" />
        <Text category="body-large" sx={{ whiteSpace: 'pre-line' }}>
          {promotion.description}
        </Text>
      </EditSection>
      <VSpacer size="5" />
      {SectionDivider}
    </>;
  }

  return <Card
    cardContentStyle={{
      p: "16px",
      "&:last-child": {
        pb: "16px",
      },
    }}
    testID="additional-information-card"
  >
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Text category="body-large">
          Additional information (optional)
        </Text>
        <VSpacer size="2" />
        <Text category="body-medium" sx={{ color: "#D8D8D8" }}>
          Provide critical promotion details such as terms & conditions, disclaimer
          text or other rules.
        </Text>
      </Stack>
      <Button
        onClick={() => setEditAdditionalInfo(true)}
        square
        startIcon={<AddIcon sx={{ width: "14px" }}/>}
        sx={{ padding: "0px 12px", height: "32px" }}
        testID="add-additional-info-button"
      >
        <Text category="label-medium" sx={{ paddingLeft: "0px" }}>
          Add
        </Text>
      </Button>
    </Stack>
  </Card>;
};

interface WorkflowStep4Props {
  navigateToStep: (step: number) => void;
  onPromotionChanged: (promotion: PromotionInputs) => void;
  promotion: PromotionInputs;
  retailerLocations?: ApiRetailerLocation[];
  selectedRetailer?: ApiRetailer;
}

export const WorkflowStep4 = ({
  navigateToStep,
  onPromotionChanged,
  retailerLocations,
  promotion,
  selectedRetailer,                
}: WorkflowStep4Props) => {
  const [showProductsForEarningSideSheet, setShowProductsForEarningSideSheet] = useState(false);
  const [showProductsForRedeemingSideSheet, setShowProductsForRedeemingSideSheet] = useState(false);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false);

  const formatDate = (date?: Date) => {
    return new Intl.DateTimeFormat('en-US',
      { month: 'short', day: 'numeric', year: 'numeric' },
    ).format(date);
  };

  const totalProductsForEarning = promotion.productIdsForEarningPoints?.length ?? 0;
  const totalProductsForRedeeming = promotion.productIdsForRedeemingPoints?.length ?? 0;

  return <>
    <Divider />
    <VSpacer size="3" />
    <EditSection
      label="Promotion name"
      onEditButtonClicked={() => navigateToStep(0)}
      value={promotion.name}
    />
    {SectionDivider}
    <EditSection
      label="Retailer"
      onEditButtonClicked={() => navigateToStep(0)}
      value={selectedRetailer?.name}
    />
    {SectionDivider}
    <EditSection
      label="Retailer locations"
      onEditButtonClicked={() => navigateToStep(0)}
      onValueClicked={() => setShowEditLocationsModal(true)}
      value={getSelectedLocationsText(
        promotion.locationIds?.length ?? 0,
        retailerLocations?.length ?? 0,
      )}
    />
    {SectionDivider}
    <EditSection
      label="Promotion sponsor"
      onEditButtonClicked={() => navigateToStep(0)}
      value={promotion.sponsorType}
    />
    {SectionDivider}
    <EditSection
      label="Running dates"
      onEditButtonClicked={() => navigateToStep(0)}
      value={`${formatDate(promotion.startDate)} – ${formatDate(promotion.endDate)}`}
    />
    {SectionDivider}
    <EditSection
      label="Loyalty value"
      onEditButtonClicked={() => navigateToStep(1)}
      value={promotion.uom === RewardsUom.Dollars
        ? `Earn ${promotion.rewardType === RewardsType.Dollars 
          ? `$${(promotion.value ?? 0).toFixed(4)}`
          : `${(promotion.value ?? 0).toFixed(3)}%`} per $1 spent`
        : `Earn $${(promotion.value ?? 0).toFixed(4)} per 1 ${promotion.uom} purchased`
      }
    />
    {SectionDivider}
    <EditSection
      label="Redeeming points"
      onEditButtonClicked={() => navigateToStep(0)}
      value={promotion.redeemOnAllProducts
        ? promotion.sponsorType === SponsorType.Retailer
          ? "All products"
          : "All selected manufacturer products"
        : "Only select products"
      }
    />
    {SectionDivider}
    <EditSection
      label="Products that EARN points"
      onEditButtonClicked={() => navigateToStep(2)}
      onValueClicked={() => setShowProductsForEarningSideSheet(true)}
      value={`${totalProductsForEarning} ${totalProductsForEarning === 1 ? "product" : "products"}`}
    />
    {!!totalProductsForRedeeming && (
      <>
        {SectionDivider}
        <EditSection
          label="Products that REDEEM points"
          onEditButtonClicked={() => navigateToStep(3)}
          onValueClicked={() => setShowProductsForRedeemingSideSheet(true)}
          value={`${totalProductsForRedeeming} ${totalProductsForRedeeming === 1 ? "product" : "products"}`}
        />
      </>
    )}
    {SectionDivider}
    <EditSection
      label="Customer Rewards Dashboard display"
      onEditButtonClicked={() => navigateToStep(0)}
    >
      <EditSectionSubLine
        label="Include the promotion within the 'Product Promotions’ carousel on the dashboard"
        value={promotion.showInDashboardCarousel}
      />
      <VSpacer size="4" />
      <EditSectionSubLine
        label="Include the promotion within the 'point value' table on the 'Program Benefits'
        tier details page"
        value={promotion.showInDashboardTable}
      />
      <VSpacer size="4" />
    </EditSection>
    {SectionDivider}
    <EditSection
      label="Points engine override"
      onEditButtonClicked={() => navigateToStep(0)}
    >
      <EditSectionSubLine
        label="Only allow manufacturer promotion points to be granted. Retailer rewards program
        points are not granted for the products included in this promotion."
        value={promotion.showInDashboardCarousel}
      />
    </EditSection>
    <VSpacer size="6" />
    <AdditionalInfoSection
      onChange={(description) => onPromotionChanged({
        ...promotion,
        description,
      })}
      promotion={promotion}
    />
    <VSpacer size="10" />
    {!!selectedRetailer && !!retailerLocations?.length && showEditLocationsModal && (
      <EditLocationsModal
        onClose={() => setShowEditLocationsModal(false)}
        onPromotionChanged={onPromotionChanged}
        promotion={promotion}
        retailerLocations={retailerLocations}
        selectedRetailer={selectedRetailer}
      />
    )}
    {(!!totalProductsForEarning && showProductsForEarningSideSheet) && (
      <AddedProductsSideSheet
        onChange={(productIds) => onPromotionChanged({
          ...promotion,
          productIdsForEarningPoints: productIds.length === 0 ? undefined : productIds,
        })}
        onClose={() => setShowProductsForEarningSideSheet(false)}
        productIds={promotion.productIdsForEarningPoints ?? []}
        productType={'earn'}
        readonly
      />
    )}
    {(!!totalProductsForRedeeming && showProductsForRedeemingSideSheet) && (
      <AddedProductsSideSheet
        onChange={(productIds) => onPromotionChanged({
          ...promotion,
          productIdsForRedeemingPoints: productIds.length === 0 ? undefined : productIds,
        })}
        onClose={() => setShowProductsForRedeemingSideSheet(false)}
        productIds={promotion.productIdsForRedeemingPoints ?? []}
        productType={'redeem'}
        readonly
      />
    )}
  </>;
};