export enum QueryKeys {
  ACTIVE_RETAILERS = 'active_retailers',
  GET_ACTIVE_INGREDIENTS = 'active-ingredients',
  GET_ACTIVE_PROGRAM_FOR_LOCATION = 'get_active_program_for_location',
  GET_ACTIVE_PROGRAM_FOR_RETAILER = 'get_active_program_for_retailer',
  GET_ADMINS = 'get_admins',
  GET_ALLOWED_COUNTIES = 'get_allowed_counties',
  GET_ASSOCIATED_SELLERS = 'get_associated_selleries',
  GET_CATEGORY = 'category',
  GET_COUNTIES = 'get_counties',
  GET_COUNTIES_BY_STATE = 'get_counties_by_state',
  GET_CROP = 'crop',
  GET_DASHBOARD_DATA = 'get_dashboard_data',
  GET_DASHBOARD_ORDERS = 'get_dashboard_orders',
  GET_DASHBOARD_SALESPERSONS = 'get_dashboard_salespersons',
  GET_FARMER_REWARDS = 'get_farmer_rewards',
  GET_FARMER_REWARDS_FOR_DASHBOARD = 'get_farmer_rewards_for_dashboard',
  GET_FARMERS = 'get_farmers',
  GET_FEATURE_FLAGS = 'get_feature_flags',
  GET_FEATURED_PRODUCTS = 'get_featuredProducts',
  GET_FEATURED_MANUFACTURERS = 'get_featured_manufacturers',
  GET_IMPORT_HISTORY_LIST = 'get_import_history_list',
  GET_IMPORT_PROCESSING_RESULT = 'get_import_processing_result',
  GET_IMPORT_IS_IMPORTING = 'get_import_is_importing',
  GET_LAST_LOOK = 'get_last_look',
  GET_LINKED_ACCOUNTS = 'get_linked_accounts',
  GET_LOCATIONS = 'get_locations',
  GET_LOCATION_BY_ID = 'get_location_by_id',
  GET_LOCATION_MEMBERS = 'get_location_members',
  GET_LOYALTY_SKUS = 'get_loyalty_skus',
  GET_MANUFACTURER_BY_ID = 'get_manufacturer_by_id',
  GET_MANUFACTURERS = 'get_manufacturers',
  GET_MANUFACTURERS_LIST = 'manufacturers',
  GET_NOTIFICATION_VALUES = 'get_notification_values',
  GET_NOTIFICATIONS = 'get_notifications',
  GET_OFFER = 'get_offer',
  GET_OFFERS = 'get_offers',
  GET_ORDERS = 'get_orders',
  GET_PACKAGE_SIZE = 'package',
  GET_PRACTICE = 'practice',
  GET_PRICING_REQUEST = 'get_pricing_request',
  GET_PRICING_REQUEST_OFFER = 'get offer',
  GET_PRICING_REQUEST_PUBLIC = 'get_pricing_request_public',
  GET_PRICING_REQUESTS = 'get_pricing_requests',
  GET_PRICING_REQUESTS_FOR_ADMIN = 'get_pricing_requests_for_admin',
  GET_PRICING_REQUESTS_FOR_RETAILER = 'get_pricing_requests_retailer',
  GET_PRIMARY_NUTRIENT = 'primary-nutrient',
  GET_PRODUCT_ALTERNATIVES_COMPANIONS = 'get_alternative_companions',
  GET_PRODUCT_ALTERNATIVES = 'get_product_alternatives',
  GET_PRODUCT_COMPANIONS = 'get_product_companions',
  GET_PRODUCT = 'product',
  GET_PRODUCTS = 'get_products',
  GET_PRODUCT_DETAILS = 'get_product_details',
  GET_PROMOTION = 'get_promotion',
  GET_PROMOTIONS = 'get_promotions',
  GET_RECOMMENDATIONS = 'get_product_recommendations',
  GET_RECOMMENDATION_BY_ID = 'get_product_recommendation_by_id',
  GET_RETAILER_FARMERS_BY_ID = 'get_retailer_farmers_by_id',
  GET_RETAILERS = 'get_hierarchy_of_retailers',
  GET_RETAILER_BY_ID = 'get_hierarchy_of_retailers_by_id',
  GET_RETAILER_ASSOCIATED_FARMERS = 'get_retailer_associated_farmers',
  GET_RETAILER_CATEGORIES = 'get_retailer_categories',
  GET_RETAILER_FOR_DASHBOARD = 'get_retailer_for_dashboard',
  GET_RETAILER_LOCATION_LIST = 'get_retailer_location_list',
  GET_RETAILER_LOCATIONS_PREFERRED = 'get_retailer_locations_preferred',
  GET_RETAILER_MEMBERS = 'get_retailer_members',
  GET_REWARDS_PROGRAM_FOR_DASHBOARD = 'get_rewards_program_for_dashboard',
  GET_REWARDS_PROGRAM_HAS_ACTIVE_USERS = 'get_rewards_program_has_active_users',
  GET_SAVED_RETAILER = 'get_saved_retailer',
  GET_SCHEDULED_NOTIFICATIONS = 'get_scheduled_notifications',
  GET_SHOP_FILTERS = 'get_shop_filters',
  GET_SHOPPING_CART = 'get_shopping_cart',
  GET_SPONSORED_BANNER = 'get_sponsored_banner',
  GET_STOREFRONT = 'get_storefront',
  GET_UOM = 'UoM',
  GET_USER = 'get_user',
  GET_USER_DEVICES = 'get_user_devices',
  GET_USER_LOCATIONS = 'get_user_locations',
  GET_USER_LOYALTY_BALANCES = 'get_user_loyalty_balances',
  GET_USERS = 'get_users',
  SAVED_RETAILERS = 'saved_retailers',
  VALIDATE_TOKEN = 'validate_token',
}
