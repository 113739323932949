import {
  Button,
  FullscreenDialog,
  SegmentedButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import {
  AddFarmerRecipientsDialogContent,
} from '@/pages/Admin/ManageNotifications/AddFarmerRecipientsDialogContent';
import {
  AddSalespersonRecipientsDialogContent,
} from '@/pages/Admin/ManageNotifications/AddSalespersonRecipientsDialogContent';
import {
  ViewAddedRecipientsModal,
} from '@/pages/Admin/ManageNotifications/ViewAddedRecipientsModal';
import { People } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { Divider, Stack } from '@mui/material';
import React, { useState } from 'react';

interface AddRecipientsDialogProps {
  farmerIds?: string[],
  onSave: (farmerIds: string[], salespersonIds: string[]) => void,
  salespersonIds?: string[],
  show: boolean,
}

type RecipientType = 'farmer' | 'salesperson';

export const AddRecipientsDialog = ({
  farmerIds = [],
  onSave,
  salespersonIds = [],
  show,
}: AddRecipientsDialogProps) => {
  const [selectedRecipientType, setSelectedRecipientType] = useState<RecipientType>('farmer');
  const [selectedFarmerIds, setSelectedFarmerIds] = useState<string[]>(farmerIds);
  const [selectedSalespersonIds, setSelectedSalespersonIds] = useState<string[]>(salespersonIds);
  const selectedIds = [...selectedFarmerIds, ...selectedSalespersonIds];
  const [showViewAddedRecipientsModal, setShowViewAddedRecipientsModal] = useState(false);


  const selectedFarmersCount = selectedFarmerIds.length;
  const selectedSalespersonsCount = selectedSalespersonIds.length;

  return (
    <>
      <FullscreenDialog
        actionButton={
          <Button
            onClick={() => onSave(selectedFarmerIds, selectedSalespersonIds)}
            testID="action-button"
          >
            Done adding recipients
          </Button>
        }
        contentStyle={{ alignSelf: 'center', width: '100%' }}
        open={show}
        testID="add-recipients-dialog"
        title="Add Recipients"
        titleDescriptor={
          <Button
            onClick={() => setShowViewAddedRecipientsModal(true)}
            startIcon={<People />}
            testID="added-recipients-button"
            variant="text"
          >
            Added recipients ({selectedIds.length})
          </Button>
        }
      >
        <Stack margin="0 auto" width="750px">
          <VSpacer size="6" />
          <Text category="title-small">
            Select recipient type:
          </Text>
          <VSpacer size="3" />
          <SegmentedButton
            color="primary"
            fullWidth
            selectedIndex={selectedRecipientType === 'farmer' ? 0 : 1}
          >
            <Button
              aria-selected={selectedRecipientType === 'farmer'}
              onClick={() => setSelectedRecipientType('farmer')}
              startIcon={selectedRecipientType === 'farmer' && <Check />}
              testID="farmer-recipient-button"
            >
              Farmers{selectedFarmersCount ? ` (${selectedFarmersCount})` : ''}
            </Button>
            <Button
              aria-selected={selectedRecipientType === 'salesperson'}
              onClick={() => setSelectedRecipientType('salesperson')}
              startIcon={selectedRecipientType === 'salesperson' && <Check />}
              testID="salesperson-recipient-button"
            >
              Salespersons{selectedSalespersonsCount ? ` (${selectedSalespersonsCount})` : ''}
            </Button>
          </SegmentedButton>
          <VSpacer size="6" />
          <Divider />
          <VSpacer size="6" />
          {selectedRecipientType === 'farmer' ? (
            <AddFarmerRecipientsDialogContent
              onChange={setSelectedFarmerIds}
              selectedRecipientIds={selectedFarmerIds}
            />
          ) : (
            <AddSalespersonRecipientsDialogContent
              onChange={setSelectedSalespersonIds}
              selectedRecipientIds={selectedSalespersonIds}
            />
          )}
        </Stack>
      </FullscreenDialog>
      {showViewAddedRecipientsModal && (
        <ViewAddedRecipientsModal
          onChange={(farmerIds, salespersonIds) => {
            setSelectedFarmerIds(farmerIds);
            setSelectedSalespersonIds(salespersonIds);
          }}
          onClose={() => setShowViewAddedRecipientsModal(false)}
          recipientIds={[...selectedFarmerIds, ...selectedSalespersonIds]}
          testID="view-added-recipients-modal"
        />
      )}
    </>
  );
};
