import {
  InputAdornment,
  InputBaseProps,
  Stack,
  SvgIconProps,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { localizeNumber, pluralize } from '@shared/utilities';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { VSpacer } from '../Spacer/VSpacer';
import { Text } from '../Text/Text';

export type InputProps = TextFieldProps & {
  characterCountdownStart?: number,
  containerSx?: SxProps,
  customInputComponentProps?: InputBaseProps['inputProps'],
  inputComponent?: any,
  inputMode?: "text" | "email" | "decimal" | "numeric" | "tel",
  isTransparent?: boolean,
  leadingIcon?: (props: SvgIconProps) => ReactNode,
  maxCharacterLimit?: number,
  maxLength?: number,
  onBlurChanged?: () => void,
  onChangeText?: (text: string) => void,
  onEnterPressed?: () => void,
  pattern?: string,
  required?: boolean,
  showCharacterCountdown?: boolean,
  showCharacterLimitMessage?: boolean,
  testID: string,
  trailingIcon?: (props: SvgIconProps) => ReactNode,
  width?: number | string,
};

export const Input = ({
  characterCountdownStart,
  containerSx,
  customInputComponentProps,
  inputComponent,
  inputMode = "text",
  isTransparent,
  leadingIcon,
  label,
  maxCharacterLimit: defaultCharacterLimit,
  maxLength,
  onBlur,
  onBlurChanged,
  onChangeText,
  onEnterPressed,
  onFocus,
  pattern,
  required,
  showCharacterCountdown = false,
  showCharacterLimitMessage = false,
  sx,
  testID,
  trailingIcon,
  value,
  width,
  ...rest
}: InputProps) => {
  characterCountdownStart = defaultCharacterLimit ?? maxLength ?? 99;
  const handleChange = useCallback((value: string) => {
    onChangeText?.(pattern
      ? (value?.match(new RegExp(pattern))?.[0] ?? "")
      : value);
  }, [onChangeText, pattern]);

  const calculateTopPadding = () => {
    if (!label && !rest.placeholder) {
      return "1px";
    }

    if (rest.placeholder && rest.multiline) {
      return "16px";
    }

    return undefined;
  };

  const IconStyles = {
    sx: {
      height: "17.49px",
      width: "17.49px",
    },
  };

  const [shrink, setShrink] = useState(value !== undefined && value !== '');
  const [focused, setFocused] = useState(false);
  const [valueOnFocus, setValueOnFocus] = useState(value);

  useEffect(() => {
    setShrink((value !== undefined && value !== '') || focused);
  }, [focused, value]);

  useEffect(() => {
    if (focused) {
      setValueOnFocus(value);
    }
  }, [focused]);

  const maxCharacterLimit = defaultCharacterLimit || maxLength || 0;
  const remainingCharacters = maxCharacterLimit - (
    typeof value === 'string' ? (value?.length ?? 0) : 0
  );

  if (!width && rest.fullWidth) {
    width = '100%';
  }

  return (
    <Stack alignItems="left" sx={{ flex: width ? 0 : 1, minWidth: width, width, ...containerSx }}>
      <TextField
        InputLabelProps={{
          sx: {
            paddingLeft: (leadingIcon && rest.variant !== 'outlined') ? "45px" : "5px",
            marginLeft: rest.variant === 'outlined' ? '-3px' : undefined,
          },
          shrink: shrink,
        }}
        InputProps={{
          endAdornment: !!trailingIcon && (
            <InputAdornment position="end">
              {trailingIcon(IconStyles)}
            </InputAdornment>
          ),
          inputComponent,
          startAdornment: !!leadingIcon && (
            <InputAdornment position="start">
              {leadingIcon(IconStyles)}
            </InputAdornment>
          ),
          sx: {
            padding: 0,
            ".MuiOutlinedInput-notchedOutline": {
              "& legend": {
                marginLeft: '-2px',
              },
              "& span": {
                paddingRight: '9px',
                marginLeft: '-2px',
              },
            },
            "&.MuiInputBase-root": {
              paddingTop: calculateTopPadding(),
              padding: 0,
            },
            ".MuiInputBase-input": {
              marginLeft: ((leadingIcon && trailingIcon) && '-2px')
              || (leadingIcon && '-5px')
              || '0px',
              paddingRight: leadingIcon ? '6px' : '16px',
              paddingLeft: leadingIcon ? '6px' : '16px',
            },
            ...(!label && {
              ".MuiInputBase-input": {
                paddingY: 0,
              },
            }),
            ...(isTransparent && {
              '.MuiFilledInput-input': {
                backgroundColor: 'transparent',
              },
              'MuiFilledInput-root': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
              },
              "&.Mui-focused": {
                backgroundColor: 'transparent',
              },
              "&.Mui-hover": {
                backgroundColor: 'transparent',
              },
            }),
          },
        }}
        autoComplete="off"
        inputProps={{
          maxLength: maxLength || maxCharacterLimit || 75,
          inputMode,
          pattern,
          "data-testid": testID,
          ...customInputComponentProps,
          sx,
        }}
        label={label ?? ''}
        onBlur={(e) => {
          setFocused(false);
          !e.target.value && setShrink(false);
          onBlur?.(e);
          if (value !== valueOnFocus) {
            onBlurChanged?.();
          }
        }}
        onChange={(event) => handleChange(event.target.value)}
        onFocus={(e) => {
          setFocused(true);
          setShrink(true);
          onFocus?.(e);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            onEnterPressed?.();
          }
        }}
        sx={{
          ".Mui-disabled.MuiInputBase-root": {
            "& fieldset": {
              borderWidth: '1px',
            },
          },
          // Workaround for https://github.com/mui/material-ui/issues/13898
          ...(!!leadingIcon && {
            '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
              transform: `translate(${rest.variant === 'outlined' ? '41px' : '1px'}, 17px)`,
            },
          }),
        }}
        value={value === undefined || value === null ? "" : value}
        variant="filled"
        {...rest}
      />
      {!!maxCharacterLimit && (showCharacterCountdown || showCharacterLimitMessage) && (
        <Stack>
          <VSpacer size="3" />
          <Text
            category="body-small"
            color={remainingCharacters < 0 ? 'error' : undefined}
          >
            {(
              typeof value === 'string' &&
              showCharacterCountdown &&
              (remainingCharacters <= characterCountdownStart))
              ? `${pluralize(remainingCharacters, 'character')} remaining`
              : (showCharacterLimitMessage ? `${localizeNumber(maxCharacterLimit, 0)} characters max` : '')
            }
          </Text>
        </Stack>
      )}
      {required && (
        <Text category="body-small" sx={{ paddingTop: "4px", paddingLeft: "16px" }}>
          *required
        </Text>
      )}
    </Stack>
  );
};
