import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { TransactionHistoryListItem } from '@/pages/Admin/FarmerList/TransactionHistoryListItem';
import { OrderTransactionsApi } from '@/utilities/api/OrdersApi';
import { OrderTransactionEndpoint } from '@api/endpoints/OrderTransactionEndpoint';
import { ApiUser } from '@api/interfaces';
import { ApiOrderTransaction } from '@api/interfaces/ApiOrderTransaction';
import { CircularProgress, Divider, Stack } from '@mui/material';
import { SharedConfig } from '@shared/SharedConfig';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React, { Fragment, useCallback } from 'react';
import { useQuery } from 'react-query';

interface TransactionHistoryDialogProps {
  customer: ApiUser,
  onClose: () => void,
  show: boolean,
}

export const TransactionHistoryDialog = ({
  customer,
  onClose,
  show,
}: TransactionHistoryDialogProps) => {
  const orderTransactionsQuery : OrderTransactionEndpoint.List.Query = {
    farmerId: customer.id,
    limit: SharedConfig.maxPageLimit,
  };

  const { data: orderTransactions, isFetching } = useQuery(
    [QueryKeys.GET_ORDERS, orderTransactionsQuery],
    () => OrderTransactionsApi.list(orderTransactionsQuery),
  );

  type Section = {
    data: ApiOrderTransaction[];
    title: string;
  };

  const groupByYear = useCallback(
    (transactions: ApiOrderTransaction[]): Section[] => {
      const grouped: { [key: string]: ApiOrderTransaction[] } = {};

      transactions.forEach((transaction) => {
        const year = new Date(transaction.orderDate).getFullYear().toString();
        if (!grouped[year]) {
          grouped[year] = [];
        }
        grouped[year].push(transaction);
      });

      return Object.keys(grouped)
        .sort((a, b) => b.localeCompare(a))
        .map((year) => ({
          title: year,
          data: grouped[year],
        }));
    },
    [],
  );

  const groupedTransactionsByYear = groupByYear(orderTransactions?.data ?? []);
  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="close-transaction-dialog-button"
          variant="contained"
        >
          Close
        </Button>
      )}
      dialogWidth="560px"
      onClose={onClose}
      open={show}
      showCloseButton={false}
      subtitle={`Customer: ${getAccountName(customer)}`}
      testID="transaction-history-dialog"
      title="Transaction history"
    >
      {isFetching &&
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      }
      {groupedTransactionsByYear.map((section, index) => (
        <Fragment key={section.title}>
          {index > 0 && (
            <VSpacer size="6" />
          )}
          <Text category="headline-medium" paddingBottom="12px" paddingTop="16px">
            {section.title}
          </Text>
          <Divider />
          {section.data.map((transaction) => (
            <Fragment key={transaction.id}>
              <TransactionHistoryListItem transaction={transaction} />
              <Divider />
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Dialog>
  );
};
