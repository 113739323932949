import { HSpacer, Text } from '@/components/DesignSystem';
import { Computer } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';

export const TestUserBadge = () => {
  return (
    <Stack alignItems="center" flexDirection="row">
      <Computer color="info" sx= {{ height: "16px", width: "16px" }} />
      <HSpacer size="2" />
      <Text category="overline" color="info">TEST USER</Text>
      <HSpacer size="5" />
    </Stack>
  );
};
