import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { HSpacer, Text } from '..';

export interface FullscreenDialogProps {
  actionButton?: ReactNode,
  children?: ReactNode,
  contentStyle?: SxProps<Theme>,
  dialogActions?: ReactNode,
  hideCloseButton?: boolean,
  onClose?: () => void,
  open?: boolean,
  sx?: SxProps<Theme>,
  testID: string,
  title?: string,
  titleComponent?: ReactNode,
  titleDescriptor?: ReactNode,
  titleStyle?: SxProps<Theme>,
}

export const FullscreenDialog = ({
  actionButton,
  children,
  contentStyle,
  dialogActions,
  hideCloseButton,
  onClose,
  open = false,
  sx,
  testID,
  title,
  titleComponent,
  titleDescriptor,
  titleStyle,
}: FullscreenDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
          backgroundImage: 'none',
          borderRadius: 0,
          padding: '8px 32px',
        },
      }}
      data-testid={testID}
      fullScreen={open}
      maxWidth={false}
      onClose={onClose}
      open={open}
      scroll="paper"
      sx={sx}
      transitionDuration={0.01}
    >
      <DialogTitle sx={titleStyle}>
        <Stack
          alignItems="center"
          data-testid={`${testID}-header`}
          direction="row"
          justifyContent="space-between"
          sx={{ height: "56px" }}
        >
          <Stack alignItems="center" direction="row">
            {!hideCloseButton && !!onClose && (
              <IconButton
                data-testid={`${testID}-close`}
                onClick={onClose}
                size="small">
                <CloseIcon />
              </IconButton>
            )}
            {!!titleComponent && titleComponent}
            {!!title && (
              <>
                <HSpacer size="3" />
                <Text category="title-large" testID={`${testID}-title`}>
                  {title}
                </Text>
              </>
            )}
            {!!titleDescriptor && (
              <>
                <HSpacer size="4" />
                <HSpacer size="5" />
                {titleDescriptor}
              </>
            )}
          </Stack>
          {!!actionButton && actionButton}
        </Stack>
      </DialogTitle>
      <DialogContent sx={contentStyle}>
        {children}
      </DialogContent>
      {!!dialogActions && (
        <DialogActions>
          {dialogActions}
        </DialogActions>)
      }
    </Dialog>
  );
};
