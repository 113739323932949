export const HomeScreenList = {
  FeaturedManufacturers: 'Featured Manufacturers',
  FeaturedProdcuts: 'Featured Products',
  SponsoredBanner: 'Sponsored Banner',
};

export const HomeScreenSelectLabels = {
  Manufacturers: 'Select a Manufacturer',
  Products: 'Select a Product',
  Retailer: 'Select a Retailer',
};

export const AddSponsoredBannerRadioGroup = [
  { label: 'Retailer', value: 'retailerId' },
  { label: 'Product', value: 'productId' },
  { label: 'Manufacturer', value: 'manufacturerId' },
];

export const ToastMessages = {
  addFarmerRewards: 'Reward points added successfully',
  addLocationCounties: 'Counties added successfully',
  addLocationSuccess: 'Location added successfully',
  addSalespersonSuccess: 'Salesperson has been added successfully',
  adminRetailerUploadCountiesFailure: 'An error has occurred',
  adminRetailerUploadCountiesSuccess: 'File Successfully Uploaded',
  adminRetailerUploadLogoFailure: 'Oops! Something went wrong. We couldn’t upload your image.',
  adminRetailerUploadLogoSuccess: 'Your logo has been successfully uploaded!',
  deleteSalesPerson: 'Salesperson successfully deleted',
  deleteCountySuccess: 'County deleted',
  farmersDeleteSuccess: 'Farmer removed',
  farmersSaveSuccess: 'Farmer Updated',
  fileUploadSuccess: 'File Uploaded successfully',
  featuredManufacturerAddSuccess: 'Manufacturer added',
  featuredManufacturerDeleteSuccess: 'Feature Manufacturer deleted',
  featuredManufacturerReOrderFailure: 'Featured Manufacturer re-ordering failed',
  featuredManufacturerReOrderSuccess: 'Featured Manufacturer re-ordered',
  featuredProductAddSuccess: 'Product added',
  featuredProductDeleteSuccess: 'Feature Product deleted',
  featuredProductReOrderFailure: 'Featured Product re-ordering failed',
  featuredProductReOrderSuccess: 'Featured Product re-ordered',
  preferredRetailerFarmerSelected: `This farmer already has another preferred location, 
    By Confirming this farmer's preferred location will get updated. This action cannot be undone.`,
  promotionCreated: 'Promotion created',
  promotionUpdated: 'Promotion updated',
  requestSubmitted: 'Request submitted successfully',
  salesPersonCreateSuccess: 'Sales Person added',
  salesPersonUpdateSuccess: 'Sales Person updated',
  serveAllCounties: 'Serving all counties',
  sponsoredBannerDeleteSuccess: 'Sponsored Banner deleted',
  sponsoredBannerReOrderFailure: 'Sponsored Banner re-ordering failed',
  sponsoredBannerReOrderSuccess: 'Sponsored Banner re-ordered',
  sponsoredBannerCreateSuccess: 'Sponsored Banner added',
  updateDefaultSalesPerson: 'Default sales person updated',
  updateLocationSuccess: 'Location updated successfully',
};

export const errorMessages = {
  imageTooLarge:
    'File size too large. Please upload an image under 5MB.',
  invalidImageType:
    'File format invalid. Allowed formats are PNG, SVG, and JPEG.',
  somethingWentWrong: 'Something went wrong, please try again later',
};

export const ConfirmationDialog = {
  activateLocationMessage: 'Are you sure you want to activate this Location?',
  activateRetailerMessage: 'Are you sure you want to activate this retailer?',
  activateLocationTitle: 'Activate Location',
  activateRetailerTitle: 'Activate Retailer',
  changeDefaultSalesPersonMessage: 'Are you sure you want to change the default salesperson?',
  changeDefaultSalesPersonTitle: 'Change Default Sales Person',
  deleteFarmerMessage: 'Are you sure you want to delete this farmer?',
  deleteFarmerTitle: 'Delete Farmer',
  deleteLocationLogoTitle: 'Delete Location Logo',
  deleteLocationLogoMessage: 'Are you sure you want to delete the Logo?',
  deleteMemberMessage: 'Are you sure you want to delete this member?',
  deleteMemberTitle: 'Delete Member',
  deleteSalesPersonMessage: 'Are you sure you want to delete this sales person?',
  deleteSalesPersonTitle: 'Delete Sales Person',
  inActivateManufacturerMessage: 'Are you sure you want to inactivate this manufacturer?',
  inActivateManufacturerTitle: 'Inactivate Manufacturer',
  inActivateRetailerMessage: 'Are you sure you want to deactivate this retailer?',
  inActivateRetailerTitle: 'Deactivate Retailer',
  inActivateLocationTitle: 'Deactivate Location',
  inActivateLocationMessage: 'Are you sure you want to deactivate this Location?',
  removeFarmerSalespersonMessage: `Are you sure you want to remove this salesperson from this product request?
  They will not be able to place an offer on the request. This action cannot be undone.`,
  removeFarmerSalespersonTitle: 'Remove Salesperson from Product Request',
};

export const AdminRetailerConstants = {
  addMemberSuccess: 'Member added successfully',
  addRetailerSuccess: 'Retailer added successfully',
  deleteMemberSuccess: 'Member deleted successfully',
  deleteMemberFailure: 'Something went wrong, please try again later',
  updateRetailerSuccess: 'Retailer updated successfully',
  updateMemberSuccess: 'Member updated successfully',
};

export const RetailerConstants = {
  defaultBrandColor: '#2a2a2a',
};

export const RewardsConstants = {
  addRewardsConfirmation: 'This action cannot be undone.',
  addRewardsDialogTitle: 'Update points',
  addRewardsMaxLimit: 9999.99,
  addRewardsMaxLimitErrorMessage: 'Max Limit is 9999.99',
  rewardsLastCreditedOnLabel: 'Last reward credited on:',
};
