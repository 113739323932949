import { Box, LinearProgress } from "@mui/material";
import React from "react";

interface ProgressBarProps {
  progress: number[],
}

export const ProgressBars = ({
  progress,
}: ProgressBarProps) => {
  return <Box
    alignItems="center"
    display="flex"
    gap={1}
    justifyContent="space-between"
    sx={{ width: '100%' }}
  >
    {progress.map((progress, i) => (
      <LinearProgress
        color="info"
        key={i}
        sx={{ bgcolor: "#5b5b5b", flex: 1, height: '2px' }}
        value={progress}
        variant="determinate"
      />
    ))}
  </Box>;
};