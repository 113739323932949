import { Button, Card, Text, VSpacer } from '@/components/DesignSystem';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import { Container } from '@mui/system';
import React, { Fragment, useState } from 'react';
import Add from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';

interface SelectRetailersListProps {
  isErpRetailersOnly?: boolean,
  onChange: (retailerIds: string[]) => void,
  selectedIds?: string[],
}

export const SelectRetailersList = ({
  isErpRetailersOnly,
  onChange,
  selectedIds = [],
}: SelectRetailersListProps) => {
  const [page, setPage] = useState(0);

  const {
    isFetching,
    getRetailers: retailers,
  } = useGetRetailers({
    limit: 20,
    page,
    ...(isErpRetailersOnly !== undefined ? { hasErpProducts: isErpRetailersOnly } : {}),
  }, true);

  return (
    <Container maxWidth="lg">
      {retailers?.data.map((retailer) => (
        <Fragment key={retailer.id}>
          <Card testID={`retailer-card-${retailer.id}`}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Stack justifyContent="flex-end">
                  <Text category="title-small">{retailer.name}</Text>
                </Stack>
              </Stack>
              <Button
                onClick={() => {
                  if (selectedIds.includes(retailer.id)) {
                    onChange(selectedIds.filter((id) => id !== retailer.id));
                  } else {
                    onChange([...selectedIds, retailer.id]);
                  }
                }}
                startIcon={selectedIds.includes(retailer.id) ? <Close /> : <Add />}
                testID={`add-retailer-button-${retailer.id}`}
                variant={selectedIds.includes(retailer.id) ? 'contained' : 'outlined'}
              >
                {selectedIds.includes(retailer.id) ? 'Remove' : 'Add'}
              </Button>
            </Stack>
          </Card>
          <VSpacer size="4" />
        </Fragment>
      ))}
      <VSpacer size="8" />
      {isFetching ? (
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {retailers && !!retailers.lastPage && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              <Pagination
                count={retailers.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={retailers.page + 1}
              />
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};
