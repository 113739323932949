import { DateTime } from 'luxon';
import { ApiRewardsTierSegment } from '@api/interfaces/ApiRewardsTierSegment';
import { ApiRewardsTier } from '@api/interfaces/ApiRewardsTier';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ResolvedRewardsProgram, ResolvedTier } from '@api/data/RewardsProgramData';
import { RewardsType } from '@shared/enums/RewardsType';
import { RewardsUom } from '@shared/enums/RewardsUom';
import { Routes } from '@/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { tierGraphics } from '@/pages/CustomerDashboard/TierImage';

export const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat('MMM d, yyyy');

export const getRemainingPctToHurdle = (
  segment: ApiRewardsTierSegment,
  totalPerSegment: ApiFarmerRewards['totalPerSegment'],
  tier: ApiRewardsTier,
) => {
  const total = totalPerSegment[segment.categoryId].total;
  const segmentToHurdle = tier.segments!.find(({ categoryId }) => (
    categoryId === segment.categoryId
  ))!;
  return (segmentToHurdle.minimumHurdle - total) / segmentToHurdle.minimumHurdle;
};

export const getHasTierGraphics = (tierNames: string[]) => {
  return tierNames.every((tierName) => (
    Object.keys(tierGraphics).includes(tierName)
  ));
};

export const getProgramInfo = (
  program: ResolvedRewardsProgram,
  rewards: ApiFarmerRewards,
  getDefaults: boolean = false,
) => {
  if (getDefaults) {
    return {
      areAllCategoriesHurdled: false,
      currentTier: {} as ResolvedTier,
      hasTierGraphics: false,
      isReachedFinalTier: false,
      isTierSpendHurdled: false,
      nextTier: {} as ResolvedTier,
      numCategoriesToHurdle: 0,
      relevantTier: {} as ResolvedTier,
      spendToHurdle: 0,
    };
  }
  const currentTier = program.tiers[rewards.currentRank!];
  const earnedTier = rewards.earnedRank !== null
    ? program.tiers[rewards.earnedRank]
    : null;
  const nextTier = (rewards.earnedRank ?? 0) < program.tiers.length - 1
    ? program.tiers[(rewards.earnedRank ?? -1) + 1]
    : null;
  const relevantTier = nextTier ?? earnedTier!;
  const isReachedFinalTier = !nextTier;
  const earnedSegmentsHurdled = relevantTier?.segments.filter((segment) => (
    rewards.totalPerSegment[segment.categoryId].total >= segment.minimumHurdle
  )).map(({ categoryId }) => categoryId) ?? [];
  const numCategoriesToHurdle = Math.max(
    relevantTier.minimumSegments - earnedSegmentsHurdled.length,
    0,
  );
  const spendToHurdle = Math.max(relevantTier.minimumDollars - rewards.totalSpend, 0);
  const isTierSpendHurdled = spendToHurdle <= 0;
  const areAllCategoriesHurdled = numCategoriesToHurdle === 0;
  const hasTierGraphics = getHasTierGraphics(program.tiers.map(({ name }) => name));

  return {
    areAllCategoriesHurdled,
    currentTier,
    hasTierGraphics,
    isReachedFinalTier,
    isTierSpendHurdled,
    nextTier,
    numCategoriesToHurdle,
    relevantTier,
    spendToHurdle,
  };
};

export const getRewardsValueText = (
  rewardsType: RewardsType | null,
  rewardsValue: number,
  uom: RewardsUom,
) => {
  if (rewardsType === RewardsType.Dollars) {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    }).format(rewardsValue);
    return uom === RewardsUom.Dollars
      ? `$${formattedValue}`
      : `$${formattedValue}/${uom}`;
  } else {
    const formattedValue = parseFloat((rewardsValue * 100).toFixed(3)).toString();
    return `${formattedValue}%`;
  }
};

export const formatAmount = (amount: number, isDollar: boolean = true, uom?: string) => {
  let result: string = '';
  if (amount >= 1000 * 1000) {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(amount / 1000 / 1000);
    result = isDollar ? `$${formattedAmount}M` : `${formattedAmount}M`;
  } else if (amount >= 1000) {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(amount / 1000);
    result = isDollar ? `$${formattedAmount}k` : `${formattedAmount}k`;
  } else {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: isDollar ? 2 : 4,
    }).format(amount);
    result = isDollar ? `$${formattedAmount}` : formattedAmount;
  }
  if (!isDollar && uom) {
    result += ` ${uom}`;
  }

  return result;
};

export const formatPoints = (points: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: points % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(points);
};

export const useDashboardNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultToAltRoutes: Partial<Record<Routes, Routes>> = {
    [Routes.CUSTOMER_DASHBOARD]: Routes.CUSTOMER_DASHBOARD_ALT,
    [Routes.CUSTOMER_DASHBOARD_BENEFITS]: Routes.CUSTOMER_DASHBOARD_BENEFITS_ALT,
    [Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS]: Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT,
    [Routes.CUSTOMER_DASHBOARD_WALLET]: Routes.CUSTOMER_DASHBOARD_WALLET_ALT,
  };

  return (route: Routes, accessToken?: string) => {
    const isAlt = location.pathname.includes('/db/');
    const path = isAlt
      ? defaultToAltRoutes[route]!.replace(':accessToken', accessToken!)
      : `${route}?accessToken=${accessToken}`;
    navigate(path);
  };
};

