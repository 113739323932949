import { ProductEndpoint } from "@api/endpoints";
import { SharedConfig } from "@shared/SharedConfig";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { ProductApi } from "@/utilities/api/ProductApi";
import { ApiProduct } from "@api/interfaces";
import { CircularProgress, Container, Divider, Stack } from "@mui/material";
import { Button, IconButton, Search, SideSheet, Text, VSpacer } from "@/components/DesignSystem";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import React, { useState } from "react";

interface AddedProductsSideSheetProps {
  onChange: (productIds: string[]) => void,
  onClose: () => void,
  productIds: string[],
  productType: 'earn' | 'redeem',
  readonly?: boolean,
}

export const AddedProductsSideSheet = ({
  onChange,
  onClose,
  productIds,
  productType,
  readonly,
} : AddedProductsSideSheetProps) => {
  const [search, setSearch] = useState("");

  const query: ProductEndpoint.ProductList.Query = {
    id: productIds,
    limit: SharedConfig.maxPageLimit,
  };
  const { data: productResponse, isFetching } = useQuery(
    [QueryKeys.GET_PRODUCT, query],
    () => ProductApi.productListData(query),
  );

  const totalProducts = productIds.length ;

  const products = productResponse?.data.filter(product => (
    product.name.includes(search) || product.manufacturer?.name?.includes(search)
  ));

  const ProductCard = ({
    product,
  } : {
    product: ApiProduct,
  }) => {
    return <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ "padding": "16px 24px" }}
    >
      <Stack>
        <Text category="body-large">
          {product.name}
        </Text>
        {!!product.manufacturer && (
          <Text category="body-small">
            {product.manufacturer.name}
          </Text>
        )}
      </Stack>
      {!readonly && (
        <IconButton
          color="primary"
          onClick={() => {
            const updatedProducts = productIds.filter(id => id != product.id);
            onChange(updatedProducts);
            if (!updatedProducts.length) {
              onClose();
            }
          }}
          testID={`${product.id}-delete-button`}
        >
          <DeleteOutline />
        </IconButton>
      )}
    </Stack>;
  };

  return <SideSheet
    onClose={onClose}
    open
    testID="product-list-sidesheet"
    title={`Added products: ${productType === 'earn' ? "EARN" : "REDEEM"}`}
  >
    <Stack direction="row" justifyContent="center">
      <Search
        onChangeText={setSearch}
        placeholder="Search"
        sx={{ padding: "0px 24px" }}
        testID={"product-list-search"}
        value={search}
      />
    </Stack>
    <VSpacer size="4" />
    <Stack>
      {isFetching
        ? <Container>
          <Stack alignItems='center'>
            <VSpacer size='14' />
            <CircularProgress />
          </Stack>
        </Container>
        : <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ padding: "0px 24px" }}
          >
            <Text category="body-medium">
              {totalProducts} {totalProducts === 1
                ? `product that ${productType === 'earn' ? "earns" : "redeems"} points`
                : `products that ${productType === 'earn' ? "earn" : "redeem"} points`
            }
            </Text>
            {!readonly && (
              <Button
                onClick={() => {
                  onChange([]);
                  onClose();
                }}
                testID={"promotion-side-sheet-remove-all-products"}
                variant="text"
              >
                Remove all
              </Button>
            )}
          </Stack>
          <VSpacer size="4" />
          {!!products && products.map((product) => (
            <Stack key={`${product.id}-side-sheet-product-card`}>
              <Divider />
              <ProductCard product={product}/>
            </Stack>
          ))}
          <VSpacer size="4" />
        </>
      }
    </Stack>
  </SideSheet>;
};
