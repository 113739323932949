import { Chip } from '@mui/material';
import { AccountGroupCustomerRole } from '@shared/enums';
import React from 'react';

interface CustomerRoleChipProps {
  customerRole: AccountGroupCustomerRole,
}

export const CustomerRoleChip = ({
  customerRole,
}: CustomerRoleChipProps) => {
  let label = '';
  let color = '';
  switch (customerRole) {
    case AccountGroupCustomerRole.Partner:
      label = 'PARTNER ACCOUNT';
      color = '#512DA8';
      break;
    case AccountGroupCustomerRole.Primary:
      label = 'PRIMARY ACCOUNT';
      color = '#0241A0';
      break;
    case AccountGroupCustomerRole.Secondary:
      label = 'SECONDARY ACCOUNT';
      color = '#01778A';
      break;
  }
  return <Chip
    label={label}
    size="xsmall"
    sx={{ backgroundColor: color, paddingTop: .3 }}
  />;
};
