import { Text, VSpacer } from "@/components/DesignSystem";
import React, { ReactNode } from "react";
import { Stack } from "@mui/system";

interface Props {
  children: ReactNode,
  title: string,
  width?: string,
}

export const WorkflowStepBase = ({
  children,
  title,
  width = "560px",
}: Props) => {
  return <Stack sx={{ margin: '0 auto', width }}>
    <Text align="center" category="headline-large">
      {title}
    </Text>
    <VSpacer size="7"/>
    {children}
  </Stack>;
};