export enum DryFormulationUnitOfMeasure {
  POUND = 'lb',
  TON = 'ton',
  OUNCE = 'oz',
}

export enum LiquidFormulationUnitOfMeasure {
  FLUID_OUNCE = 'fl oz',
  PINT = 'pt',
  QUART = 'qt',
  GALLON = 'gal',
  TOTE = 'tote',
}

export enum SeedApplicationUnitOfMeasure {
  MSDS = 'msds',
  KSDS = 'ksds',
  LBS = 'lbs',
}

export enum SeedProductUnitOfMeasure {
  POUND = 'lb',
  BAG = 'bag',
  BUSHEL = 'bu',
}

export enum ServiceUnitOfMeasure {
  ACRE = 'ac',
}

export const ProductUomEnums = [
  DryFormulationUnitOfMeasure,
  LiquidFormulationUnitOfMeasure,
  SeedProductUnitOfMeasure,
  ServiceUnitOfMeasure,
];

export type ApplicationUom =
  SeedApplicationUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure;

export type ProductUom =
  SeedProductUnitOfMeasure
  | LiquidFormulationUnitOfMeasure
  | DryFormulationUnitOfMeasure
  | ServiceUnitOfMeasure;

export enum UomGroup {
  DRY = 'DRY',
  LIQUID = 'LIQUID',
  OTHER = 'OTHER',
}

export const parseDryFormulationUom = (uom: string): DryFormulationUnitOfMeasure | null => {
  const DryFormulationUnitOfMeasureMapping: Record<DryFormulationUnitOfMeasure, string[]> = {
    [DryFormulationUnitOfMeasure.OUNCE]: [DryFormulationUnitOfMeasure.OUNCE],
    [DryFormulationUnitOfMeasure.POUND]: [DryFormulationUnitOfMeasure.POUND],
    [DryFormulationUnitOfMeasure.TON]: [DryFormulationUnitOfMeasure.TON, 'tn', 'tons'],
  };
  const dryValues: DryFormulationUnitOfMeasure[] = Object.values(DryFormulationUnitOfMeasure);
  for (const dryValue of dryValues) {
    const mappedUomValues = DryFormulationUnitOfMeasureMapping[dryValue];
    const mappingRegex = new RegExp(`^(${mappedUomValues.join('|')})$`, 'i');
    if (mappingRegex.test(uom)) {
      return dryValue as DryFormulationUnitOfMeasure;
    }
  }
  return null;
};

export const parseLiquidFormulationUom = (uom: string): LiquidFormulationUnitOfMeasure | null => {
  const LiquidFormulationUnitOfMeasureMapping: Record<LiquidFormulationUnitOfMeasure, string[]> = {
    [LiquidFormulationUnitOfMeasure.FLUID_OUNCE]: [LiquidFormulationUnitOfMeasure.FLUID_OUNCE],
    [LiquidFormulationUnitOfMeasure.GALLON]: [
      LiquidFormulationUnitOfMeasure.GALLON, 'ga', 'gallon', 'gallons',
    ],
    [LiquidFormulationUnitOfMeasure.PINT]: [LiquidFormulationUnitOfMeasure.PINT],
    [LiquidFormulationUnitOfMeasure.QUART]: [LiquidFormulationUnitOfMeasure.QUART],
    [LiquidFormulationUnitOfMeasure.TOTE]: [LiquidFormulationUnitOfMeasure.TOTE],
  };
  const liquidValues: LiquidFormulationUnitOfMeasure[] =
    Object.values(LiquidFormulationUnitOfMeasure);
  for (const liquidValue of liquidValues) {
    const mappedUomValues = LiquidFormulationUnitOfMeasureMapping[liquidValue];
    const mappingRegex = new RegExp(`^(${mappedUomValues.join('|')})$`, 'i');
    if (mappingRegex.test(uom)) {
      return liquidValue as LiquidFormulationUnitOfMeasure;
    }
  }
  return null;
};
