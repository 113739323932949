import { Button, Dialog, Text } from '@/components/DesignSystem';
import { ApiFarmerSalesperson } from '@api/interfaces';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React from 'react';

interface AssociatedSellersDialogProps {
  associatedSellers: ApiFarmerSalesperson[],
  onClose: () => void,
  show: boolean,
}

export const AssociatedSellersDialog = ({
  associatedSellers,
  onClose,
  show,
}: AssociatedSellersDialogProps) => {
  const tableRows = associatedSellers.map((seller) => (
    <TableRow key={seller.id}>
      <TableCell>
        <Text category="title-small">{getAccountName(seller.salesperson!)}</Text>
      </TableCell>
      <TableCell>
        <Text category="body-medium">{seller.commodityAssignment ?? ''}</Text>
        </TableCell>
    </TableRow>
  ));

  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="close-associated-sellers-dialog-button"
          variant="contained"
        >
          Close
        </Button>
      )}
      dialogWidth="560px"
      onClose={onClose}
      open={show}
      showCloseButton={false}
      testID="associated-sellers-dialog"
      title="Associated sellers"
    >
      <Table>
        <TableHead>
          <TableCell width="50%">
            <Text category="overline">SELLER NAME</Text>
          </TableCell>
          <TableCell width="50%">
            <Text category="overline">SELLER ASSIGNMENT</Text>
            </TableCell>
        </TableHead>
        {tableRows.length
          ? tableRows
          : (
            <TableRow>
              <TableCell colSpan={2}>No associated sellers</TableCell>
            </TableRow>
          )
        }
      </Table>
    </Dialog>
  );
};
