import { Button, HSpacer, VSpacer } from '@/components/DesignSystem';
import { FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { Action } from '@/components/shared/ListSelector/helper';
import { RetailerCard } from '@/components/shared/ListSelector/RetailerCard';
import { RetailerListToolbar } from '@/components/shared/ListSelector/RetailerListToolbar';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiRetailer } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';

export type SelectType = 'locations' | 'products' | 'salespersons';

interface RetailersListProps {
  onChange: (updatedIds: string[]) => void,
  onSelectRetailer: (retailer: ApiRetailer) => void,
  selectedIds: string[],
  selectType?: SelectType,
}

export const RetailersList = ({
  onChange,
  onSelectRetailer,
  selectedIds,
  selectType = 'salespersons',
}: RetailersListProps) => {
  const [page, setPage] = useState(0);
  const [isAddingAll, setIsAddingAll] = useState(false);
  const { setSearch, debouncedSearch } = useSearch(1);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  const selectedCountyIds = Array.from(filterSelections?.get('state-county-filter') ?? []);
  const selectedLocationIds = Array.from(filterSelections?.get('location-filter') ?? []);

  const retailerFilters: RetailerEndpoint.List.Query = {
    countyId: selectedCountyIds.length ? selectedCountyIds : undefined,
    isActive: true,
    locationId: selectedLocationIds.length ? selectedLocationIds : undefined,
    search: debouncedSearch || undefined,
  };

  const {
    isFetching,
    getRetailers,
  } = useGetRetailers({
    ...retailerFilters,
    limit: 20,
    page,
  }, true);

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, selectionsString]);

  const addAll = async () => {
    setIsAddingAll(true);
    const newSelectedIds = new Set(selectedIds);
    const retailers = await HierarchyOfRetailersApi.listRetailers(retailerFilters, true);
    if (selectType === 'salespersons') {
      retailers.data.forEach((retailer) => {
        retailer.locationDetails?.forEach((location) => {
          location.salespersons.forEach((salesperson) => newSelectedIds.add(salesperson.id));
        });
      });
    } else if (selectType === 'locations') {
      retailers.data.forEach((retailer) => {
        retailer.locationDetails?.forEach((location) => {
          newSelectedIds.add(location.id);
        });
      });
    }
    onChange(Array.from(newSelectedIds));
    setIsAddingAll(false);
  };

  const removeAll = () => {
    onChange([]);
  };

  const showRemoveAllButton = !!selectedIds.length;
  const showAddAllButton = selectType !== 'products';
  return (
    <Container maxWidth="lg">
      <RetailerListToolbar
        filterSelections={filterSelections}
        onChangeSearch={(search) => {
          setSearch(search ?? '');
        }}
        onChangeSelections={setFilterSelections}
        testID="retailer-list-toolbar"
        totalItems={getRetailers?.total}
        totalUnit="retailer"
      />
      <Stack flexDirection="row" justifyContent="flex-end">
        {showRemoveAllButton && (
          <Button
            onClick={removeAll}
            testID="remove-all-button"
            variant="text"
          >
            Remove all
          </Button>
        )}
        {showRemoveAllButton && showAddAllButton && (
          <HSpacer size="5" />
        )}
        {showAddAllButton && (
          <Button
            loading={isAddingAll}
            onClick={addAll}
            startIcon={<AddIcon />}
            testID="add-all-button"
          >
            Add all
          </Button>
        )}
      </Stack>
      <VSpacer size="5" />
      {getRetailers?.data.map((retailer) => (
        <React.Fragment key={retailer.id}>
          <RetailerCard
            onAction={(action: Action) => {
              const updatedSelectedIds = new Set(selectedIds);
              if (selectType === 'salespersons') {
                const salespersonIds = retailer.locationDetails?.flatMap(
                  (location) => location.salespersons.map((salesperson) => salesperson.id),
                ) ?? [];
                salespersonIds.forEach((id) => {
                  if (action === Action.AddAll) {
                    updatedSelectedIds.add(id);
                  } else {
                    updatedSelectedIds.delete(id);
                  }
                });
              } else if (selectType === 'locations') {
                const locationIds = retailer.locationDetails?.map((location) => location.id) ?? [];
                locationIds.forEach((id) => {
                  if (action === Action.AddAll) {
                    updatedSelectedIds.add(id);
                  } else {
                    updatedSelectedIds.delete(id);
                  }
                });
              } else if (selectType === 'products') {
                const productIds = retailer.productRetailers?.map(
                  (productRetailer) => productRetailer.productId,
                ) ?? [];
                productIds.forEach((id) => {
                  if (action === Action.AddAll) {
                    updatedSelectedIds.add(id);
                  } else {
                    updatedSelectedIds.delete(id);
                  }
                });
              }
              onChange(Array.from(updatedSelectedIds));
            }}
            onSelectRetailer={onSelectRetailer}
            retailer={retailer}
            selectType={selectType}
            selectedIds={selectedIds}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
      <VSpacer size='8' />
      {isFetching ? (
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='center'
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {getRetailers && !!getRetailers.lastPage && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
            >
              <Pagination
                count={getRetailers.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={getRetailers.page + 1}
              />
            </Stack>
          )}
        </>
      )}
    </Container>
  );
};
