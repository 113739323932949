import { Button, Modal, Text } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { UserApi } from '@/utilities/api/UserApi';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';

interface CustomerDevicesModalProps {
  customerId: string,
  onClose: () => void,
  open: boolean,
}

export const CustomerDevicesModal = ({
  customerId,
  onClose,
  open,
}: CustomerDevicesModalProps) => {
  const { openSnackbar } = useSnackbar();
  const { data: devices } = useQuery(
    [QueryKeys.GET_USER_DEVICES, customerId],
    () => UserApi.getDevicesForUser(customerId), {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error has occurred fetching user devices');
      },
    },
  );

  const DisplayDevices = () => {
    if (!devices || devices.length === 0) {
      return (<Text category='body-medium'>
        No registered devices
      </Text>);
    }
    return <Grid columnSpacing={12} container direction='row' rowSpacing={4}>
      {devices.map((device) => (
        <Grid item key={device.id} xs={4}>
          <Stack direction='row' justifyContent='space-between' width='100%'>
            <Text category='body-medium'>
              Device ID {device.deviceId}:
            </Text>
            <Text category='title-small'>
              {device.isNotificationsEnabled ? 'Enabled' : 'Disabled'}
            </Text>
          </Stack>
        </Grid>
      ))}
    </Grid>;
  };

  return (
    <Modal
      cancelButton={(props) => (
        <Button
          onClick={onClose}
          {...props}
          variant="text"
        >
          Close
        </Button>
      )}
      onClose={onClose}
      open={open}
      testID='farmer-devices-modal'
      title='Devices'
    >
      <DisplayDevices />
    </Modal>
  );
};
