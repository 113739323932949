import { Text, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import {
  getCurrentRank,
  getCurrentTier,
  getFarmerRewardsProfile,
  getNextTier,
} from '@/pages/Admin/FarmerList/helper';
import { UserApi } from '@/utilities/api/UserApi';
import { SubledgerBalance } from '@api/interfaces';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { formatCurrency } from '@shared/utilities';
import { useQuery } from 'react-query';

interface RewardsProgramStatusCardsProps {
  customerId: string,
  farmerRewards: ApiFarmerRewards | null,
  hideTotalPoints?: boolean,
  isRewardsFetching: boolean,
  rewardsProgram: ApiRewardsProgram,
}

export const RewardsProgramStatusCards = ({
  customerId,
  farmerRewards,
  hideTotalPoints = false,
  isRewardsFetching,
  rewardsProgram,
}: RewardsProgramStatusCardsProps) => {
  const { data: farmerWallets, isFetching: isWalletsFetching } = useQuery(
    [QueryKeys.GET_USER_LOYALTY_BALANCES, customerId],
    () => UserApi.getLoyaltyBalances(customerId),
    { enabled: !hideTotalPoints },
  );

  const rewardsProfile = getFarmerRewardsProfile(farmerRewards, rewardsProgram);

  const currentRank = getCurrentRank(rewardsProfile, rewardsProgram);

  const currentTierName = !rewardsProfile || currentRank === null
    ? 'Not yet started'
    : getCurrentTier(currentRank, rewardsProgram.tiers ?? [])?.name;

  const wallets: SubledgerBalance[] = farmerWallets?.subledgerBalances
    ? Object.values(farmerWallets.subledgerBalances)
    : [];

  const totalPoints = wallets.reduce((sum, wallet) => sum + wallet.points, 0);
  const totalSpend = formatCurrency(rewardsProfile?.totalSpend ?? 0);

  const getTotalSpendGoal = (currentRank: number) => {
    const nextTier = getNextTier(currentRank, rewardsProgram.tiers ?? []);
    if (nextTier) {
      return nextTier.minimumDollars;
    }
    const currentTier = getCurrentTier(currentRank, rewardsProgram.tiers ?? []);
    return currentTier?.minimumDollars ?? 0;
  };

  const getSegmentsGoal = (currentRank: number) => {
    const nextTier = getNextTier(currentRank, rewardsProgram.tiers ?? []);
    if (nextTier) {
      return nextTier.minimumSegments;
    }
    const currentTier = getCurrentTier(currentRank, rewardsProgram.tiers ?? []);
    return currentTier?.minimumSegments ?? 0;
  };

  const getSegmentsHurdled = (currentRank: number | null) => {
    const nextTier = getNextTier(currentRank, rewardsProgram.tiers ?? []);
    if (nextTier) {
      return farmerRewards?.segmentsHurdled.length ?? 0;
    }
    if (currentRank === null) {
      return 0;
    }
    const currentTier = getCurrentTier(currentRank, rewardsProgram.tiers ?? []);
    return currentTier?.minimumSegments ?? 0;
  };

  const columnSize = hideTotalPoints ? 4 : 3;
  return (
    <Grid container spacing={2}>
      <Grid item md={columnSize}>
        <Stack bgcolor="#2A2A2A" borderRadius="4px" height="100%" p="14px 16px 16px 16px">
          <Text category="label-medium">
            Current status
          </Text>
          <VSpacer size="2" />
          {isRewardsFetching ? (
            <CircularProgress size={14} />
          ) : (
            <Text category="body-medium">
              {currentTierName}
            </Text>
          )}
        </Stack>
      </Grid>
      {!hideTotalPoints && (
        <Grid item md={columnSize}>
          <Stack bgcolor="#2A2A2A" borderRadius="4px" height="100%" p="14px 16px 16px 16px">
            <Text category="label-medium">
              Total points
            </Text>
            <VSpacer size="2" />
            {isWalletsFetching ? (
              <CircularProgress size={14} />
            ) : (
              <Text category="body-medium">
                {formatCurrency(totalPoints, '', 2)}
              </Text>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item md={columnSize}>
        <Stack bgcolor="#2A2A2A" borderRadius="4px" height="100%" p="14px 16px 16px 16px">
          <Text category="label-medium">
            Total spend
          </Text>
          <VSpacer size="2" />
          {isRewardsFetching ? (
            <CircularProgress size={14} />
          ) : (
            <Text category="body-medium">
              {totalSpend} of {
                formatCurrency(currentRank === null ? 0 : getTotalSpendGoal(currentRank))
              }
            </Text>
          )}
        </Stack>
      </Grid>
      <Grid item md={columnSize}>
        <Stack bgcolor="#2A2A2A" borderRadius="4px" height="100%" p="14px 16px 16px 16px">
          <Text category="label-medium">
            Categories completed
          </Text>
          <VSpacer size="2" />
          {isRewardsFetching ? (
            <CircularProgress size={14} />
          ) : (
            <Text category="body-medium">
              {getSegmentsHurdled(currentRank)} of {currentRank === null
                ? 0 : getSegmentsGoal(currentRank)}
            </Text>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
