import { useHideBackdrop } from '@/hooks/useHideBackdrop';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Stack,
  SxProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { ButtonProps } from '../Button/Button';
import { HSpacer, VSpacer } from '../Spacer';
import { Text, TextProps } from '../Text/Text';
import { Pixels } from '@/themes/variant-interfaces/Pixels';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

export interface DialogProps {
  acceptButton?: (props: ButtonProps) => ReactNode,
  cancelButton?: (props: ButtonProps) => ReactNode,
  children?: ReactNode,
  dialogWidth?: Pixels,
  fullWidth?: boolean,
  heroIcon?: ReactNode,
  isTitleCentered?: boolean,
  loading?: boolean,
  maxWidth?: MuiDialogProps['maxWidth'],
  onClose: () => void,
  open?: boolean,
  px?: number | string,
  secondaryActionButton?: (props: ButtonProps) => ReactNode,
  showCloseButton?: boolean,
  showTitle?: boolean,
  subtitle?: string,
  testID: string,
  title?: string,
  titleProps?: TextProps,
  sx?: SxProps,
}

const positionTitle = (isCentered: boolean) => (isCentered ? 'center' : 'left');

export const Dialog = ({
  acceptButton,
  cancelButton,
  children,
  dialogWidth,
  fullWidth = true,
  heroIcon,
  isTitleCentered = false,
  open = false,
  px,
  loading = false,
  maxWidth = 'xs',
  onClose,
  secondaryActionButton,
  showCloseButton = true,
  showTitle = true,
  subtitle,
  testID,
  title,
  titleProps,
  sx,
}: DialogProps) => {
  const AcceptButtonProps: ButtonProps = {
    loading,
    testID: `${testID}-accept`,
    variant: "text",
  };
  const CancelButtonProps: ButtonProps = {
    disabled: loading,
    testID: `${testID}-cancel`,
    variant: "text",
  };
  const SecondaryActionButtonProps: ButtonProps = {
    disabled: loading,
    testID: `${testID}-secondary-action`,
    variant: "text",
  };

  const styles: SXStyles = {
    closeButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 13,
      top: 13,
      width: 48,
      height: 48,
    },
  };

  const { hide: hideBackdrop, transitionDuration } = useHideBackdrop(open);

  return (
    <MuiDialog
      PaperProps={{ sx: { maxWidth: dialogWidth, ...sx } }}
      componentsProps={{
        backdrop: {
          style: {
            opacity: hideBackdrop ? 0 : 1,
            transitionDuration,
          },
        },
      }}
      data-testid={testID}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
    >
      {showCloseButton && (
        <Box
          data-testid={`${testID}-close`}
          onClick={onClose}
          sx={styles.closeButton}
        >
          <Close sx={{ width: 22.6, height: 22.6 }} />
        </Box>
      )}
      {heroIcon && (
        <>
          <VSpacer size="5" />
          <Stack alignItems="center">
            <DialogContent data-testid={`${testID}-hero-icon`} sx={{ p: '0' }}>
              {heroIcon}
            </DialogContent>
          </Stack>
        </>
      )}
      {showTitle && (
        <DialogTitle
          data-testid={`${testID}-title`}
          fontSize={24}
          sx={{
            p: '24px 24px 16px',
            textAlign: positionTitle(isTitleCentered || !!heroIcon),
          }}
        >
          <Text category="headline-small" sx={{ overflowWrap: 'break-word' }} {...titleProps}>
            {title}
          </Text>
        </DialogTitle>
      )}
      {!!subtitle && (
        <DialogTitle
          data-testid={`${testID}-subtitle`}
          fontSize={14}
          sx={{
            p: '0 24px 16px',
            textAlign: positionTitle(isTitleCentered || !!heroIcon),
          }}
        >
          <Text
            category="body-medium"
            sx={{ overflowWrap: 'break-word' }}
            {...titleProps}
          >
            {subtitle}
          </Text>
        </DialogTitle>
      )}
      <DialogContent data-testid={`${testID}-content`} sx={{ px }}>
        {children}
      </DialogContent>
      <DialogActions sx={{
        justifyContent: !secondaryActionButton ? "flex-end" : "space-between",
        paddingTop: (cancelButton || acceptButton) ? '24px' : 0,
      }}>
        {secondaryActionButton?.(SecondaryActionButtonProps)}
        <Stack direction="row">
          {cancelButton?.(CancelButtonProps)}
          <HSpacer size="3" />
          {acceptButton?.(AcceptButtonProps)}
        </Stack>
      </DialogActions>
      <VSpacer size="7" />
    </MuiDialog>
  );
};
