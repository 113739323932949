import { Box } from '@mui/material';
import bronzeIcon from '@/assets/icons/precious_metal_bronze.png';
import silverIcon from '@/assets/icons/precious_metal_silver.png';
import goldIcon from '@/assets/icons/precious_metal_gold.png';
import platinumIcon from '@/assets/icons/precious_metal_platinum.png';
import darkBlueIcon from '@/assets/icons/tier_graphic_dark_blue.png';
import { ApiRewardsTier } from '@api/interfaces/ApiRewardsTier';

export const tierGraphics = {
  Bronze: bronzeIcon,
  Silver: silverIcon,
  Gold: goldIcon,
  Platinum: platinumIcon,
  Elite: darkBlueIcon,
  Diamond: darkBlueIcon,
} as const;

export interface TierImageProps {
  tier: ApiRewardsTier,
  hasTierGraphics: boolean,
}

export const TierImage = ({
  tier,
  hasTierGraphics,
}: TierImageProps) => {
  const src = (hasTierGraphics && tier.name in tierGraphics)
    ? tierGraphics[tier.name as keyof typeof tierGraphics]
    : undefined;

  return (
    <Box
      alt="Tier image"
      component="img"
      src={src}
      sx={{ height: '52px', objectFit: 'contain', width: '64px' }}
    />
  );
};
