import { ToastMessages } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import { SetErrorFunction } from '@/pages/Admin/HierarchyOfRetailers/Locations/Types';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RetailerEndpoint, RetailerLocationEndpoint } from '@api/endpoints';
import { ApiCounty } from '@api/interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';


const useAddRetailerLocation = (onClose: (args: boolean) => void, setError: SetErrorFunction) => {
  const { openSnackbar: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: addRetailerLocation, isLoading } = useMutation(
    (args: RetailerLocationEndpoint.Create.Request) =>
      HierarchyOfRetailersApi.addRetailerLocation(args),
    {
      onSuccess: async () => {
        onClose(false);
        await queryClient.invalidateQueries(
          QueryKeys.GET_RETAILER_LOCATION_LIST,
        );
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_LOCATIONS_PREFERRED);
        showSnackbar(ToastMessages.addLocationSuccess);
      },
      onError: (error: DetailedApiError) => {
        setError && setError((prev) => ({
          ...(prev),
          serverError: error.message,
        }));
      },
    },
  );

  return { addRetailerLocation, isAddLocationLoading: isLoading };
};

const useGetAllRetailerLocationsPreferred = (id: string) => {
  const queryClient = useQueryClient();
  const { data: areAllRetailerLocationsPreferred, isLoading } = useQuery(
    [QueryKeys.GET_RETAILER_LOCATIONS_PREFERRED, id],
    () => HierarchyOfRetailersApi.getAreRetailerLocationsPreferred(id),
  );

  const invalidateAreAllRetailerLocationsPreferred = async () => {
    await queryClient.invalidateQueries([QueryKeys.GET_RETAILER_LOCATIONS_PREFERRED]);
  };

  return {
    areAllRetailerLocationsPreferred,
    invalidateAreAllRetailerLocationsPreferred,
    isLoading,
  };
};

const useGetRetailerLocations = (
  id: string,
  query: RetailerEndpoint.List.Query,
) => {
  const queryClient = useQueryClient();
  const { data: retailerLocations, isLoading } = useQuery(
    [QueryKeys.GET_RETAILER_LOCATION_LIST, id, JSON.stringify(query)],
    () => HierarchyOfRetailersApi.getRetailerLocations(id, query),
    { enabled: !!id },
  );

  const invalidateRetailerLocations = async () => {
    await queryClient.invalidateQueries([QueryKeys.GET_RETAILER_LOCATION_LIST]);
  };

  return { retailerLocations, invalidateRetailerLocations, isLoading };
};

const useGetLocationMembers = (id: string, search: string) => {
  const { data: locationMembers, isLoading } = useQuery(
    [QueryKeys.GET_LOCATION_MEMBERS, id, search],
    () => HierarchyOfRetailersApi.getLocationMembers(id, search),
  );

  return { locationMembers, isLoading };
};

const useGetLocationById = (id: string) => {
  const { data: location, isLoading } = useQuery(
    [QueryKeys.GET_LOCATION_BY_ID, id],
    () => HierarchyOfRetailersApi.getRetailerLocationById(id),
  );

  const queryClient = useQueryClient();
  const invalidateRetailerLocation = async () => {
    await queryClient.invalidateQueries([QueryKeys.GET_LOCATION_BY_ID]);
  };

  return { location, invalidateRetailerLocation, isLoading };
};

const useEditRetailerLocation = (
  id: string,
  onClose?: (args: boolean) => void,
) => {
  const queryClient = useQueryClient();
  const { mutate: updateRetailerLocation, isLoading } = useMutation(
    (args: RetailerLocationEndpoint.Update.Request) =>
      HierarchyOfRetailersApi.updateRetailerLocation(id, args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_LOCATION_BY_ID);
        await queryClient.invalidateQueries(
          QueryKeys.GET_RETAILER_LOCATION_LIST,
        );
        onClose && onClose(false);
      },
    },
  );

  return { updateRetailerLocation, isLoading };
};

const useGetCounties = (id: string) => {
  const { data: getCounties, isLoading } = useQuery(
    [QueryKeys.GET_COUNTIES, id],
    () => HierarchyOfRetailersApi.getCounties(id),
  );

  return { getCounties, isLoading };
};

const useGetCountiesByState = (
  state: string,
  setCounties: React.Dispatch<Partial<ApiCounty[]>>,
) => {
  const { data: getCountiesByState, isLoading } = useQuery(
    [QueryKeys.GET_COUNTIES_BY_STATE, state],
    () => HierarchyOfRetailersApi.getCountiesByState(state),
    {
      enabled: !!state,
      onSuccess: (data) => {
        setCounties(data);
      },
    },
  );

  return { getCountiesByState, isLoading };
};

const useAddCounties = (
  locationId: string,
  onClose?: (args: boolean) => void,
) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: addCounties, isLoading } = useMutation(
    (args: RetailerLocationEndpoint.UpdateAllowedCounties.Request) =>
      HierarchyOfRetailersApi.addCounties(locationId, args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_COUNTIES);
        await queryClient.invalidateQueries(QueryKeys.GET_LOCATION_BY_ID);
        openSnackbar(ToastMessages.addLocationCounties);
        onClose && onClose(false);
      },
      onError: (error: DetailedApiError) => {
        openSnackbar(error.message);
        onClose && onClose(false);
      },
    },
  );

  return { addCounties, isLoading };
};

const useUpdateLocation = (id: string, onClose: () => void, setError: SetErrorFunction) => {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { mutate: updateLocation, isLoading } = useMutation(
    (args: RetailerLocationEndpoint.Update.Request) =>
      HierarchyOfRetailersApi.updateRetailerLocation(id, args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_LOCATION_BY_ID);
        openSnackbar(ToastMessages.updateLocationSuccess);
        onClose();
      },
      onError: (error: DetailedApiError) => {
        setError((prev) => ({
          ...prev,
          serverError: error.message,
        }));
      },
    },
  );

  return { updateLocation, isLoading };
};

const useDeleteLocationCounty = (locationId: string) => {
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: deleteLocationCounty, isLoading } = useMutation(
    (countyId: string) =>
      HierarchyOfRetailersApi.deleteLocationCounty(locationId, countyId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_COUNTIES);
        openSnackbar(ToastMessages.deleteCountySuccess);
      },
      onError: (error: DetailedApiError) => {
        openSnackbar(error.message);
      },
    },
  );

  return { deleteLocationCounty, isLoading };
};

export {
  useAddRetailerLocation,
  useGetAllRetailerLocationsPreferred,
  useGetRetailerLocations,
  useGetLocationMembers,
  useGetLocationById,
  useEditRetailerLocation,
  useGetCounties,
  useGetCountiesByState,
  useAddCounties,
  useUpdateLocation,
  useDeleteLocationCounty,
};
