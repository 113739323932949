import { AppHeader } from '@/components/shared/AppHeader';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { useHideBackdrop } from '@/hooks/useHideBackdrop';
import { SpacerSize } from '@/themes/variant-interfaces/Size';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Close from '@mui/icons-material/Close';
import { Box, IconButton, Modal as MuiModal, SxProps, Theme, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import { HSpacer } from '..';
import { ButtonProps } from '../Button/Button';
import { VSpacer } from '../Spacer/VSpacer';
import { Text, TextProps } from '../Text/Text';

export interface ModalProps {
  acceptButton?: (props: ButtonProps) => ReactNode,
  backgroundColor?: string,
  cancelButton?: (props: ButtonProps) => ReactNode,
  children?: ReactNode,
  containerStyleOverride?: SxProps<Theme>,
  disableBackdropClick?: boolean,
  footerSpacerSize?: SpacerSize,
  footerStyle?: SxProps<Theme>,
  headerAccessoryLeft?: ReactNode,
  headerAccessoryRight?: ReactNode,
  heroIcon?: ReactNode,
  hideHeaderOnMobile?: boolean,
  hideOnlyHeaderOnMobile?: boolean,
  largeModal?: boolean,
  loading?: boolean,
  onClose: () => void,
  open?: boolean,
  overline?: string,
  padding?: number,
  secondaryActionButton?: (props: ButtonProps) => ReactNode,
  subline?: string | ((props: TextProps) => ReactNode),
  testID: string,
  title?: string,
  titleSpacerSize?: SpacerSize,
  width?: number,
  overflow?:string
}

export const Modal = ({
  acceptButton,
  backgroundColor,
  cancelButton,
  children,
  containerStyleOverride,
  disableBackdropClick,
  footerSpacerSize = "10",
  footerStyle,
  headerAccessoryLeft,
  headerAccessoryRight,
  heroIcon,
  hideHeaderOnMobile = false,
  hideOnlyHeaderOnMobile = false,
  largeModal = false,
  loading = false,
  onClose,
  open = false,
  overline,
  padding = 4,
  secondaryActionButton,
  subline,
  testID,
  title,
  overflow="auto",
  titleSpacerSize = (headerAccessoryLeft
    || headerAccessoryRight
    || overline
    || subline
    || title) ? "8" : undefined,
  width = 480,
}: ModalProps) => {

  const theme = useTheme();
  backgroundColor = backgroundColor ?? theme.palette.background.default;
  const containerStyle = () => {
    return !largeModal
      ? ({
        backgroundColor,
        borderRadius: "16px",
        boxShadow: 24,
        left: "50%",
        maxHeight: '80%',
        maxWidth: 728,
        minWidth: width ? undefined : 380,
        overflow: overflow,
        p: padding,
        paddingTop: 3,
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width,
      } as SXStyles)
      : ({
        backgroundColor,
        borderRadius: "16px",
        boxShadow: 24,
        height: "calc(100vh - 180px)",
        margin: "60px",
        p: padding,
      } as SXStyles);
  };

  const styles = {
    desktop: {
      container: { ...(containerStyle()), ...containerStyleOverride },
      footer: {
        ...footerStyle,
      },
    },
    mobile: {
      headerBox: {
        backgroundColor,
        borderRadius: "24px",
        padding: "20px",
        marginTop: "74px",
        height: "100%",
      },
      headerIconButton: {
        position: "fixed",
        right: "20px",
      },
      container: {
        backgroundColor,
        height: 'calc(100% - 80px)',
        overflow: 'auto',
        padding: '16px',
        ...containerStyleOverride,
      },
    },
    modal: {
      boxSizing: 'border-box',
      overflow: 'auto',
    },
  } as const;

  const DesktopButtonSize = largeModal ? "giant" : "medium";

  const AcceptButtonDesktopProps: ButtonProps = {
    loading,
    size: DesktopButtonSize,
    testID: `${testID}-accept`,
    variant: "text",
  };

  const CancelButtonDesktopProps: ButtonProps = {
    disabled: loading,
    size: DesktopButtonSize,
    testID: `${testID}-cancel`,
    variant: "text",
  };

  const SecondaryActionButtonDesktopProps: ButtonProps = {
    loading,
    size: DesktopButtonSize,
    testID: `${testID}-secondary`,
    variant: "text",
  };

  const AcceptButtonMobileProps: ButtonProps = {
    loading,
    size: "giant",
    testID: `${testID}-accept`,
  };

  const CancelButtonMobileProps: ButtonProps = {
    disabled: loading,
    size: "giant",
    testID: `${testID}-cancel`,
    variant: "outlined",
  };

  const SecondaryActionButtonMobileProps: ButtonProps = {
    loading,
    size: "giant",
    testID: `${testID}-secondary`,
    variant: "outlined",
  };

  const sublineProps: TextProps = {
    category: 'p2',
  };

  const calculateBodySize = () => {
    let baseHeight = 380;

    if (!subline) {
      baseHeight -= 32;
    }
    if (!overline) {
      baseHeight -= 16;
    }
    if (!title) {
      baseHeight -= 34;
    }

    if (cancelButton || acceptButton) {
      baseHeight += 50;
    }

    return baseHeight;
  };

  const { hide: hideBackdrop, transitionDuration } = useHideBackdrop(open);

  return (
    <MuiModal
      componentsProps={{
        backdrop: {
          style: {
            opacity: hideBackdrop ? 0 : 1,
            transitionDuration,
          },
        },
      }}
      data-testid={testID}
      onClick={(event) => event.stopPropagation()}
      onClose={(event, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      open={open}
      sx={styles.modal}
    >
      <>
        <DesktopOnly>
          <Box sx={styles.desktop.container}>
            <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
              <Box alignItems="center" display="flex">
                {headerAccessoryLeft}
                {
                  !!headerAccessoryLeft && <HSpacer size='4' />
                }
                <Stack>
                  {!!overline && <Text category="overline">{overline}</Text>}
                  {!!title && <Text breakWord category="headline-small">
                    {title}
                  </Text>}
                  {!!subline && (
                    <>
                      <VSpacer size="4" />
                      {typeof subline === 'string'
                        ? (<Text {...sublineProps}>{subline}</Text>)
                        : subline(sublineProps)
                      }
                    </>
                  )}
                </Stack>
              </Box>
              {headerAccessoryRight}
            </Stack>
            <VSpacer size={largeModal ? "8" : titleSpacerSize} />
            {(!!heroIcon && !largeModal) && (
              <Box textAlign="center">
                {heroIcon}
                <VSpacer size="7" />
              </Box>
            )}
            {!largeModal ? children : (
              <Box sx={{
                height: `calc(100vh - ${calculateBodySize()}px)`,
                overflowX: "auto",
              }}>
                {children}
              </Box>
            )}
            {(!!acceptButton || !!cancelButton) && (
              <Stack sx={styles.desktop.footer}>
                <VSpacer size={largeModal ? "8" : footerSpacerSize} />
                <Stack
                  direction="row"
                  justifyContent={!secondaryActionButton ? "flex-end" : "space-between"}
                >
                  {!!secondaryActionButton && (
                    cancelButton?.(CancelButtonDesktopProps)
                  )}
                  <Stack direction="row">
                    {secondaryActionButton ? (
                      secondaryActionButton(SecondaryActionButtonDesktopProps)
                    ) : (
                      cancelButton?.(CancelButtonDesktopProps)
                    )}
                    <HSpacer size="4" />
                    {acceptButton?.(AcceptButtonDesktopProps)}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Box>
        </DesktopOnly>
        <MobileOnly>
          {hideHeaderOnMobile ? (
            <Box sx={styles.mobile.headerBox}>
              <Box sx={styles.mobile.headerIconButton}>
                <IconButton
                  onClick={onClose}
                  size="small">
                  <Close />
                </IconButton>
              </Box>
              {(overline || (!overline && title)) && (
                <Stack direction="row" justifyContent="center">
                  <Text breakWord fontSize="16px" fontWeight="500">
                    {overline || title}
                  </Text>
                </Stack>
              )}
              <VSpacer size="11" />
              {!!heroIcon &&
                <Box textAlign="center">
                  {heroIcon}
                  <VSpacer size="7" />
                </Box>
              }
              {children}
            </Box>
          ) : (
            <>
              {!hideOnlyHeaderOnMobile && <AppHeader />}
              <Box sx={styles.mobile.container}>
                <Stack>
                  <VSpacer size="6" />
                  {!!title && (
                    <Text breakWord category="h5">{title}</Text>
                  )}
                  {subline ? (
                    <>
                      <VSpacer size="4" />
                      {typeof subline === 'string'
                        ? (<Text {...sublineProps}>{subline}</Text>)
                        : subline(sublineProps)
                      }
                      <VSpacer size="8" />
                    </>
                  ) : (
                    <VSpacer size="9" />
                  )}
                  {!!heroIcon && (
                    <Box textAlign="center">
                      {heroIcon}
                      <VSpacer size="7" />
                    </Box>
                  )}
                  {children}
                  {(!!acceptButton || !!cancelButton) && (
                    <Stack>
                      <VSpacer size="12" />
                      <Stack alignItems="center">
                        {acceptButton?.(AcceptButtonMobileProps)}
                        {!!secondaryActionButton && (
                          <>
                            <VSpacer size="9" />
                            {secondaryActionButton?.(SecondaryActionButtonMobileProps)}
                          </>
                        )}
                        <VSpacer size="9" />
                        {cancelButton?.(CancelButtonMobileProps)}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </>
          )}
        </MobileOnly>
      </>
    </MuiModal>
  );
};
