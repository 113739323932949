export function min (a: number, b: number) {
  return a < b ? a : b;
}
export function max (a: number, b: number) {
  return a > b ? a : b;
}

export function countDecimals (value: number) {
  if ((value % 1) != 0)
    return value.toString().split(".")[1].length;
  return 0;
}
