import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { LinkedAccountCard } from '@/pages/Admin/FarmerList/LinkedAccountCard';
import { ApiAccountGroupCustomer } from '@api/interfaces';
import { Divider } from '@mui/material';
import { AccountGroupCustomerRole } from '@shared/enums';
import { getAccountName } from '@shared/utilities/UserUtilities';
import React, { Fragment, useMemo } from 'react';

interface LinkedAccountsDialogProps {
  linkedAccounts: ApiAccountGroupCustomer[],
  onClose: () => void,
  show: boolean,
}

export const LinkedAccountsDialog = ({
  linkedAccounts,
  onClose,
  show,
}: LinkedAccountsDialogProps) => {
  const roleOrder: { [key in AccountGroupCustomerRole]: number } = {
    [AccountGroupCustomerRole.Primary]: 1,
    [AccountGroupCustomerRole.Partner]: 2,
    [AccountGroupCustomerRole.Secondary]: 3,
  };

  const sortedAccounts = useMemo(() => linkedAccounts.sort((a, b) => {
    const roleComparison = roleOrder[a.customerRole] - roleOrder[b.customerRole];

    if (roleComparison !== 0) {
      return roleComparison;
    }

    const aAccountName = getAccountName(a.customer!);
    const bAccountName = getAccountName(b.customer!);
    return aAccountName.localeCompare(bAccountName);
  }), [linkedAccounts]);


  return (
    <Dialog
      acceptButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          testID="close-linked-accounts-dialog-button"
          variant="contained"
        >
          Close
        </Button>
      )}
      dialogWidth="560px"
      onClose={onClose}
      open={show}
      showCloseButton={false}
      testID="linked-accounts-dialog"
      title="Linked accounts"
    >
      <Divider />
      {!sortedAccounts.length && (
        <>
          <VSpacer size="5" />
          <Text>No linked accounts</Text>
        </>
      )}
      {sortedAccounts.map((account) => (
        <Fragment key={account.id}>
          <LinkedAccountCard account={account} />
          <Divider />
        </Fragment>
      ))}
    </Dialog>
  );
};
