import { Button, FullscreenDialog } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { SelectRetailersList } from '@/components/shared/ListSelector/SelectRetailersList';

interface AddRetailersDialogProps {
  onSave: (retailerIds: string[]) => void,
  selectedIds?: string[],
}

export const AddRetailersDialog = ({
  onSave,
  selectedIds = [],
}: AddRetailersDialogProps) => {
  const [selectedRetailerIds, setSelectedRetailerIds] = useState<string[]>(selectedIds);

  return (
    <FullscreenDialog
      actionButton={
        <Button
          onClick={() => onSave(selectedRetailerIds)}
          testID="action-button"
        >
          Done adding retailers
        </Button>
      }
      contentStyle={{ alignSelf: 'center', width: '100%' }}
      open
      testID="add-retailers-dialog"
      title="Add Retailers"
    >
      <Stack margin="0 auto" width="750px">
        <SelectRetailersList
          isErpRetailersOnly
          onChange={(retailerIds) => setSelectedRetailerIds(retailerIds)}
          selectedIds={selectedRetailerIds}
        />
      </Stack>
    </FullscreenDialog>
  );
};
