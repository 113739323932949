import GrowersIcon from '@/assets/icons/growers-icon.svg';
import ManufacturerIcon from '@/assets/icons/manufacturer-icon.svg';
import RetailerIcon from '@/assets/icons/retailer-icon.svg';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { ApiRewardsTier } from '@api/interfaces/ApiRewardsTier';
import { RewardsUom } from '@shared/enums/RewardsUom';
import { formatCurrency } from '@shared/utilities';

export interface Wallet {
  points: number,
  manufacturerId: string | null,
  manufacturerName: string | null,
  name: string,
  promotionId: string | null,
  retailerId: string | null,
  retailerName: string | null,
}

export const formatHurdleText = (
  amount: number,
  uom: RewardsUom,
) => {
  return uom === RewardsUom.Dollars
    ? formatCurrency(amount)
    : `${amount} ${uom}`;
};

export const getCurrentRank = (
  farmerRewards: ApiFarmerRewards | undefined | null,
  rewardsProgram: ApiRewardsProgram,
) => {
  let currentRank = farmerRewards?.currentRank ?? null;
  const tierZero = rewardsProgram.tiers?.find((tier) => tier.rank === 0);
  if (
    currentRank === null
    && tierZero?.minimumDollars === 0
    && tierZero?.minimumSegments === 0
  ) {
    currentRank = 0;
  }
  return currentRank;
};

export const getCurrentTier = (currentRank: number, tiers: ApiRewardsTier[]) => {
  return tiers?.find(
    (t) => t.rank === currentRank,
  );
};

export const getNextTier = (currentRank: number | null, tiers: ApiRewardsTier[]) => {
  return tiers?.find(
    (t) => currentRank === null ? t.rank === 0 : t.rank === currentRank + 1,
  );
};

export const getIcon = (retailerId: string | null, manufacturerId: string | null) => {
  if (!manufacturerId && !retailerId) {
    return GrowersIcon;
  } else if (manufacturerId) {
    return ManufacturerIcon;
  }
  return RetailerIcon;
};

export const getFarmerRewardsProfile = (
  farmerReward: ApiFarmerRewards | null,
  rewardsProgram: ApiRewardsProgram | null,
) => {
  if (farmerReward) {
    return farmerReward;
  }
  if (rewardsProgram
    && rewardsProgram.tiers
    && rewardsProgram.tiers.find((tier) => tier.rank === 0)?.minimumDollars === 0) {
    return {
      accessToken: '',
      earnedRank: null,
      id: '',
      currentRank: 0,
      program: rewardsProgram,
      programId: rewardsProgram.id,
      rankOverride: null,
      segmentsHurdled: [],
      totalPerSegment: {},
      totalSpend: 0,
    } as ApiFarmerRewards;
  }
  return undefined;
};
