import { Stack } from "@mui/material";
import {
  Button,
  HSpacer,
  InfoTooltip,
  SegmentedButton,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import { SponsorType } from "@shared/enums";
import { Check } from "@mui/icons-material";

interface SponsorSelectorProps {
  onChange: (sponsorType: SponsorType) => void,
  value: SponsorType,
}

export const SponsorSelector = ({
  onChange,
  value,
} : SponsorSelectorProps) => {
  const selectedSponsorIndex = value === SponsorType.Retailer ? 0 : 1;

  return <Stack>
    <Stack alignItems="center" direction="row">
      <Text category="title-medium">
        Promotion Sponsor
      </Text>
      <HSpacer size="3"/>
      <InfoTooltip>
        Select the entity sponsoring the promotion. Points earned from this promotion will be
        paid for by the selected entity. For example: a retailer may be offering a promotion to
        their customers that is sponsored by the manufacturer. In this case, the points earned
        will be deposited into the farmers "Manufacturer" wallet with that retailer and when
        applied to an order, the manufacturer will pay for the cost of those points.
      </InfoTooltip>
    </Stack>
    <VSpacer size="4"/>
    <Stack direction="row">
      <SegmentedButton
        color="primary"
        fullWidth
        selectedIndex={selectedSponsorIndex}
      >
        <Button
          onClick={() => {
            onChange(SponsorType.Retailer);
          }}
          startIcon={selectedSponsorIndex === 0 && <Check/>}
          testID="retailer-sponsor-button"
        >
          Retailer
        </Button>
        <Button
          onClick={() => {
            onChange(SponsorType.Manufacturer);
          }}
          startIcon={selectedSponsorIndex === 1 && <Check/>}
          testID="manufacturer-sponsor-button"
        >
          Manufacturer
        </Button>
      </SegmentedButton>
    </Stack>
  </Stack>;
};