import { Filter, VSpacer } from "@/components/DesignSystem";
import ERPRetailerSelector from '@/components/shared/ListSelector/ERPRetailerSelector';
import { useUserList } from '@/hooks/useUserList';
import { UserEndpoint } from '@api/endpoints';
import { ApiRetailer, ApiUser } from "@api/interfaces";
import { Container, Divider, Pagination, Stack } from "@mui/material";
import { UserType } from "@shared/enums";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomerCard from "./CustomerCard";

enum QueryParams {
  'retailerId' = 'retailerId',
  'search' = 'search',
}

const CustomerList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = (
    useState<UserEndpoint.List.Query>({ })
  );
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer>();

  const { users } = useUserList({
    ...selectedFilters,
    retailerId: selectedRetailer ? selectedRetailer.id : null,
    userType: [UserType.Farmer],
  });

  useEffect(() => {
    const searchParams: { [param: string]: string } = {};
    if (selectedRetailer) {
      searchParams[QueryParams.retailerId] = selectedRetailer.id;
    }
    if (selectedFilters.search) {
      searchParams[QueryParams.search] = selectedFilters.search;
    }
    setSearchParams(searchParams);
  }, [selectedFilters, selectedRetailer]);

  return (
    <Container maxWidth="lg">
      <Stack>
        <VSpacer size="8" />
        <ERPRetailerSelector
          defaultMenuItem={{
            label: 'GROWERS',
            logo: 'none',
          }}
          hasErpProducts={true}
          header='Customer accounts'
          onSelect={(retailer) => setSelectedRetailer(retailer)}
          selectedId={searchParams.get(QueryParams.retailerId)}
        />
        <VSpacer size="6" />
        <Divider />
        <VSpacer size="6" />
        <Filter<UserEndpoint.List.Query & { numPricingRequests: string }>
          filters={[]}
          initialSearch={searchParams.get(QueryParams.search) ?? undefined}
          onChange={(filters) => {
            setSelectedFilters({ ...filters });
          }}
          recordName="customer account"
          testID="farmer-list-filter"
          totalResults={users?.total}
        />
        {!!users && users.data.map((user) => (
          <Fragment key={user.id}>
            <CustomerCard customer={user as ApiUser}/>
            <VSpacer size="3" />
          </Fragment>
        ))}
        {!!users && users.lastPage !== 0 &&
          <Stack alignItems="center">
            <VSpacer size="9" />
            <Pagination
              count={users.lastPage + 1}
              onChange={
                (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
              }
              page={users.page + 1}
            />
          </Stack>
        }
        <VSpacer size="14" />
      </Stack>
    </Container>
  );
};

export default CustomerList;

