import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { getFarmerRewardsProfile } from '@/pages/Admin/FarmerList/helper';
import { RewardsProgramProgressModal } from '@/pages/Admin/FarmerList/RewardsProgramProgressModal';
import { RewardsProgramStatusCards } from '@/pages/Admin/FarmerList/RewardsProgramStatusCards';
import { ApiFarmerRewards } from '@api/interfaces/ApiFarmerRewards';
import { ApiRewardsProgram } from '@api/interfaces/ApiRewardsProgram';
import { Leaderboard } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useState } from 'react';

interface RewardsProgramStatusProps {
  customerId: string,
  farmerRewards: ApiFarmerRewards[],
  isRewardsFetching: boolean,
  rewardsProgram: ApiRewardsProgram,
}

export const RewardsProgramStatus = ({
  customerId,
  farmerRewards,
  isRewardsFetching,
  rewardsProgram,
}: RewardsProgramStatusProps) => {

  const [showProgressModal, setShowProgressModal] = useState(false);

  const farmerReward = farmerRewards.find(
    (rewards) => rewards.programId === rewardsProgram.id,
  ) ?? null;

  const rewardsProfile = getFarmerRewardsProfile(farmerReward, rewardsProgram);
  const earningText = `${rewardsProfile !== null ? 're-' : ''}earning`;

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Text category="body-xlarge">
            Rewards program
          </Text>
          <Text category="body-medium">
            Progress towards {earningText} your loyalty status
          </Text>
        </Stack>
        {!!rewardsProfile?.accessToken && (
          <Button
            color="inherit"
            onClick={() => {
              const domain = window.location.href.split("#")[0];
              window.open(
                `${domain}#${Routes.CUSTOMER_DASHBOARD_ALT.replace(
                  ':accessToken',
                  rewardsProfile.accessToken,
                )}`,
                '_blank',
              );
            }}
            testID="customer-dashboard-button"
            variant="outlined"
          >
            Customer dashboard
          </Button>
        )}
      </Stack>
      <VSpacer size="5" />
      <RewardsProgramStatusCards
        customerId={customerId}
        farmerRewards={farmerReward}
        isRewardsFetching={isRewardsFetching}
        rewardsProgram={rewardsProgram}
      />
      <VSpacer size="3" />
      <Stack alignItems="flex-end">
        <Button
          onClick={() => setShowProgressModal(true)}
          startIcon={<Leaderboard />}
          testID="view-progress-button"
          variant="text"
        >
          View progress
        </Button>
      </Stack>
      <RewardsProgramProgressModal
        customerId={customerId}
        farmerRewards={farmerReward}
        isRewardsFetching={isRewardsFetching}
        onClose={() => setShowProgressModal(false)}
        open={showProgressModal}
        rewardsProgram={rewardsProgram}
      />
    </Stack>
  );
};
