import {
  HSpacer,
  IconButton,
  Text,
  TextBadge,
  TopAppBar,
  VSpacer,
} from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { ButtonBase, CircularProgress, Container, Divider, Stack, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatAmount, getProgramInfo, useDashboardNavigate } from './helpers';
import { TierImage } from '@/pages/CustomerDashboard/TierImage';
import { FiberManualRecord, KeyboardArrowUp } from '@mui/icons-material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useDashboard } from '@/hooks/useDashboard';
import { BenefitCard } from '@/pages/CustomerDashboard/Benefits/BenefitCard';
import { AwardPointsEarned } from '@/pages/CustomerDashboard/Benefits/AwardPointsEarned';
import { defaultDashboardData } from '@/pages/CustomerDashboard/CustomerDashboard';
import { ApiCategory } from '@api/interfaces';
import { ResolvedTier } from '@api/data/RewardsProgramData';

export const BenefitsPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigateToPage = useDashboardNavigate();

  const { data: dashboardData } = useDashboard(accessToken);

  const {
    categories,
    rewards,
    program,
    retailer,
  } = dashboardData ?? defaultDashboardData;

  const {
    hasTierGraphics,
  } = useMemo(() => (
    getProgramInfo(program, rewards, !dashboardData)
  ), [dashboardData, program, rewards]);

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const Header = (
    <TopAppBar
      isStatic
      leftAccessory={(
        <IconButton
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD, accessToken)}
          testID="back-button"
        >
          <ArrowBack />
        </IconButton>
      )}
      rightAccessory={<HSpacer size="7" />}
      sx={{ bgcolor: retailer.brandColor }}
      testID="top-app-bar"
    >
      <Text category="title-large" sx={{ color: 'white' }}>
        Program Benefits
      </Text>
    </TopAppBar>
  );

  return  (
    <>
      <Stack
        bgcolor="#F7F7F7"
        color="black"
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        <VSpacer size="3" />
        <Divider light />
        <Stack divider={<Divider light />}>
          {program.tiers.map((tier) => (
            <TierBenefitsCard
              brandColor={retailer.brandColor}
              categories={categories}
              hasTierGraphics={hasTierGraphics}
              isActive={tier.rank === rewards.currentRank}
              key={tier.id}
              numCategoriesToHurdle={tier.minimumSegments}
              spendToHurdle={tier.minimumDollars}
              testID={`tier-${tier.rank}-benefits-card`}
              tier={tier}
            />
          ))}
        </Stack>
        <Divider light />
      </Stack>
    </>
  );
};

interface TierBenefitsCardProps {
  brandColor: string,
  categories: ApiCategory[],
  isActive: boolean,
  hasTierGraphics: boolean,
  numCategoriesToHurdle: number,
  spendToHurdle: number,
  testID: string,
  tier: ResolvedTier,
}

const TierBenefitsCard = ({
  brandColor,
  categories,
  isActive,
  hasTierGraphics,
  numCategoriesToHurdle,
  spendToHurdle,
  testID,
  tier,
}: TierBenefitsCardProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const categoryHurdleText = numCategoriesToHurdle > 0
    ? `Hurdle ${numCategoriesToHurdle} ${numCategoriesToHurdle === 1 ? 'category' : 'categories'}`
    : null;
  const spendHurdleText = spendToHurdle > 0
    ? `Spend ${formatAmount(spendToHurdle)}`
    : null;

  const BulletPoint = (
    <Stack alignItems="center" justifyContent="center" minHeight="20px" minWidth="20px">
      <FiberManualRecord sx={{ height: '6px', width: '6px' }}/>
    </Stack>
  );

  return (
    <ButtonBase onClick={() => setIsExpanded(!isExpanded)}>
      <Stack bgcolor="white" width="100%">
        <Stack alignItems="center" direction="row" justifyContent="space-between" p="16px">
          <Stack direction="row">
            {hasTierGraphics && (
              <>
                <TierImage hasTierGraphics={hasTierGraphics} tier={tier} />
                <HSpacer size="3"/>
              </>
            )}
            <Stack>
              <Stack alignItems="center" direction="row">
                <Text fontSize="20px" fontWeight={500}>
                  {tier.name} Tier
                </Text>
                {isActive && (
                  <>
                    <HSpacer size="4" />
                    <TextBadge text="ACTIVE" />
                  </>
                )}
              </Stack>
              <VSpacer size="1" />
              {spendHurdleText && (
                <Stack direction="row">
                  {BulletPoint}
                  <Text category="body-medium">
                    {spendHurdleText}
                  </Text>
                </Stack>
              )}
              {categoryHurdleText && (
                <Stack direction="row">
                  {BulletPoint}
                  <Text category="body-medium">
                    {categoryHurdleText}
                  </Text>
                </Stack>
              )}
            </Stack>
          </Stack>
          <HSpacer size="3" />
          <IconButton
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ color: theme.palette.grey[400] }}
            testID={`${testID}-expand-button`}
            variant="outlined"
          >
            {isExpanded ? (
              <KeyboardArrowUp sx={{ color: 'black', height: '24px', width: '24px' }}/>
            ) : (
              <KeyboardArrowDown sx={{ color: 'black', height: '24px', width: '24px' }}/>
            )}
          </IconButton>
        </Stack>
        {isExpanded && (
          <Stack gap="16px" pb="32px" px="16px">
            {tier.benefits.map((benefit) => (
              <BenefitCard
                benefit={benefit}
                brandColor={brandColor}
                hasTierGraphics={hasTierGraphics}
                key={benefit.id}
                testID={`${testID}-benefit-card-${benefit.id}`}
                tierName={tier.name}
              />
            ))}
            <AwardPointsEarned
              categories={categories}
              testID={`${testID}-award-points-earned`}
              tier={tier}
            />
          </Stack>
        )}
      </Stack>
    </ButtonBase>
  );
};

