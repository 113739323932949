import { Text } from '@/components/DesignSystem';
import { KeyboardArrowUp } from '@mui/icons-material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  IconButton,
  Table as MuiTable,
  TableCell,
  TableHead,
  TableProps as MuiTableProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
} from '@mui/material';
import { Children, ReactElement, ReactNode, useState } from 'react';

export interface TableProps extends MuiTableProps {
  headers: (ReactNode | string)[],
  headerWidths?: (number | undefined)[],
  isFixed?: boolean,
  rows?: ReactNode[][],
}

export interface TableRowProps extends MuiTableRowProps {
  expandedComponent?: ReactNode,
  values: ReactNode[],
}

export const Table = ({
  children,
  headers,
  headerWidths,
  rows,
  ...rest
}: TableProps) => {
  const hasExpandableRow = Children.toArray(children).some((child) => (
    (child as ReactElement).props.expandedComponent
  ));

  return (
    <MuiTable {...rest} sx={{
      bgcolor: '#1B1B1B',
      tableLayout: headerWidths?.length ? 'fixed' : undefined,
    }}>
      <TableHead>
        <MuiTableRow>
          {hasExpandableRow && (
            <TableCell sx={{ borderBottom: '1px solid #FFFFFF7A', width: '60px' }} />
          )}
          {headers.map((header, index) => (
            <TableCell
              key={index}
              sx={{
                borderBottom: '1px solid #FFFFFF7A',
              }}
              width={headerWidths?.[index] ? headerWidths[index] : 'auto'}
            >
              <Text category="label-large">{header}</Text>
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
      {children ?? rows?.map((row, i) => (
        <MuiTableRow key={i}>
          {row.map((value, j) => (
            <TableCell key={j}>
              {value}
            </TableCell>
          ))}
        </MuiTableRow>
      ))}
    </MuiTable>
  );
};

export const TableRow = ({
  expandedComponent,
  values,
  ...rest
}: TableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <MuiTableRow {...rest}>
        {!!expandedComponent && (
          <TableCell sx={{ borderBottom: '1px solid #FFFFFF0F' }}>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
        )}
        {values.map((value, i) => (
          <TableCell key={i} sx={{ borderBottom: '1px solid #FFFFFF0F' }}>
            {value}
          </TableCell>
        ))}
      </MuiTableRow>
      {isExpanded && expandedComponent}
    </>
  );
};
